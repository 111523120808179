import EmptyResult from "@components/disclosure/home/result/empty";
import PaginationContainer from "@components/disclosure/home/result/pagination";
import DisclosureRow from "@components/disclosure/home/result/row";
import type { getDisclosuresQuery as getDisclosuresQueryType } from "@query/__generated__/getDisclosuresQuery.graphql";
import { GetDisclosuresQuery } from "@query/get";
import { useLazyLoadQuery } from "react-relay";
import styled from "styled-components";

const ResultContainerWrapper = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

interface ResultContainerProps {
  search: string;
  page: number;
  setPage: (page: number) => void;
}

const ResultRowContainer = styled.div`
  flex: 1;
`;

const ResultContainer: React.FC<ResultContainerProps> = (props) => {
  const { search, page, setPage } = props;
  const variables = {
    search: search,
    page: page,
  };
  const data = useLazyLoadQuery<getDisclosuresQueryType>(
    GetDisclosuresQuery,
    variables,
    {
      fetchPolicy: "network-only",
    }
  );
  return (
    data && (
      <ResultContainerWrapper>
        {data?.disclosures.data.length > 0 ? (
          <ResultRowContainer>
            {data?.disclosures.data.map((item, index) => (
              <DisclosureRow
                key={`공시-${index}`}
                item={item}
                index={index}
                page={page}
                total={data?.disclosures.meta.pagination.total}
              />
            ))}
          </ResultRowContainer>
        ) : (
          <EmptyResult />
        )}
        <PaginationContainer
          page={page}
          setPage={setPage}
          pagination={data.disclosures.meta.pagination}
        />
      </ResultContainerWrapper>
    )
  );
};
export default ResultContainer;
