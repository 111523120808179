import { ReactComponent as ArrowLeftSVG } from "@assets/icon/arrow-left.svg";
import { ReactComponent as ArrowRightSVG } from "@assets/icon/arrow-right.svg";
import styled from "styled-components";

interface PaginationContainerProps {
  page: number;
  setPage: (page: number) => void;
  pagination: {
    readonly __typename: "Pagination";
    readonly page: number;
    readonly pageCount: number;
    readonly pageSize: number;
    readonly total: number;
  };
}

const getPaginationIndexList = (
  currentPage: number,
  totalPages: number
): number[] => {
  let startPage = currentPage - 2;
  if (startPage < 1) {
    startPage = 1;
  }
  let endPage = startPage + 4;
  if (endPage > totalPages) {
    endPage = totalPages;
    startPage = endPage - 4;
    if (startPage < 1) {
      startPage = 1;
    }
  }
  return Array.from(
    { length: endPage - startPage + 1 },
    (_, index) => startPage + index
  );
};

interface PatinationButtonProps {
  hidden: boolean;
}

const PatinationButton = styled.button<PatinationButtonProps>`
  margin-top: 100px;
  line-height: 1 !important;
  padding: 4px;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  vertical-align: baseline;

  ${({ theme }) => theme.media.tabletL`
    width: 30px;
    height: 30px;
    margin-top: 60px;
  `}

  ${({ theme }) => theme.media.tabletS`
    width: 24px;
    height: 24px;
    margin-top: 60px;
  `}

  ${({ theme }) => theme.media.mobile`
    width: 20px;
    height: 20px;
    margin-top: 20px;
  `}

  background-color: ${({ theme, hidden }) =>
    hidden ? "transparent" : theme.color.gray100};
  color: ${({ theme, hidden }) =>
    hidden ? "transparent" : theme.color.gray500};

  cursor: ${({ hidden }) => (hidden ? "unset" : "pointer")};

  &:hover {
    background-color: ${({ theme, hidden }) =>
      hidden ? "transparent" : theme.color.gray200};
  }

  svg {
    display: ${({ hidden }) => (hidden ? "none" : "block")};
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0px 16px;
`;

const ArrowLeftIcon = styled(ArrowLeftSVG)``;
const ArrowRightIcon = styled(ArrowRightSVG)``;

const PaginationContainer: React.FC<PaginationContainerProps> = (props) => {
  const { page, setPage, pagination } = props;
  const indexList = getPaginationIndexList(page, pagination.pageCount);
  const hiddenLeft = indexList.length <= 0 || indexList[0] <= 1;
  const hiddenRight =
    indexList.length <= 0 ||
    indexList[indexList.length - 1] <= pagination.pageCount;

  const onLeftClick = () => {
    if (hiddenLeft) {
      return;
    } else {
      setPage(page - 1);
    }
  };

  const onRightClick = () => {
    if (hiddenRight) {
      return;
    } else {
      setPage(page + 1);
    }
  };

  return (
    <PaginationWrapper>
      <PatinationButton hidden={hiddenLeft} onClick={onLeftClick}>
        <ArrowLeftIcon />
      </PatinationButton>
      {indexList.map((index) => (
        <PatinationButton
          key={`page-button-${index}`}
          hidden={false}
          onClick={() => setPage(index)}
        >
          {index}
        </PatinationButton>
      ))}
      <PatinationButton hidden={hiddenRight} onClick={onRightClick}>
        <ArrowRightIcon />
      </PatinationButton>
    </PaginationWrapper>
  );
};
export default PaginationContainer;
