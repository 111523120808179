import { ContentContainer, LanguageSwitcher } from "@components/common/etc";
import SectionLabel from "@components/common/label";
import { TitleText } from "@components/culture/common";
import { FirstSectionWithBanner } from "@components/layout/banner";
import { FontTypes } from "@style/fonts";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const LifeSectionOneWrapper = styled(FirstSectionWithBanner)`
  background-color: ${({ theme }) => theme.color.deepBlue};
  color: white;
`;

const LifeSectionWrapper = styled.section`
  background-color: ${({ theme }) => theme.color.deepBlue};
  color: white;
`;

const DescriptionText = styled.p`
  ${({ theme }) => theme.fonts.body3}

  ${({ theme }) => theme.media.mobile`
    ${theme.fonts.body4}
  `}
`;
interface DescriptionTextContainerProps {
  itemKey: string;
}
const DescriptionTextContainer: React.FC<DescriptionTextContainerProps> = (
  props
) => {
  const { itemKey } = props;
  const { t } = useTranslation("culture");
  return (
    <LanguageSwitcher
      target="culture"
      KoreanContainer={() => (
        <DescriptionText>
          {t(`life.${itemKey}.description1`)}
          <br />
          {t(`life.${itemKey}.description2`)}
        </DescriptionText>
      )}
      EnglishContainer={() => (
        <DescriptionText>{t(`life.${itemKey}.description`)}</DescriptionText>
      )}
    />
  );
};

const LifeSectionOne = () => {
  const { t } = useTranslation("culture");
  return (
    <LifeSectionOneWrapper>
      <ContentContainer padding={{ tabletSmall: 80 }}>
        <SectionLabel
          languageFileName="culture"
          labelKey="life.label"
          fontType={{ tabletSmall: FontTypes.subtitle2 }}
        />
        <LanguageSwitcher
          target="culture"
          KoreanContainer={() => <TitleText>{t("life.item1.title")}</TitleText>}
          EnglishContainer={() => (
            <TitleText>
              {t("life.item1.title1")}
              <br />
              {t("life.item1.title2")}
            </TitleText>
          )}
        />
        <DescriptionTextContainer itemKey="item1"/>
      </ContentContainer>
    </LifeSectionOneWrapper>
  );
};

const LifeSectionTwo = () => {
  const { t } = useTranslation("culture");
  return (
    <LifeSectionWrapper>
      <ContentContainer padding={{ tabletSmall: 80 }}>
        <TitleText>{t("life.item2.title")}</TitleText>
        <DescriptionTextContainer itemKey="item2"/>
      </ContentContainer>
    </LifeSectionWrapper>
  );
};

const LifeSectionThree = () => {
  const { t } = useTranslation("culture");
  return (
    <LifeSectionWrapper>
      <ContentContainer padding={{ tabletSmall: 80 }}>
        <TitleText>{t("life.item3.title")}</TitleText>
        <DescriptionTextContainer itemKey="item3"/>
      </ContentContainer>
    </LifeSectionWrapper>
  );
};

const LifeSection = {
  One: LifeSectionOne,
  Two: LifeSectionTwo,
  Three: LifeSectionThree,
};

export default LifeSection;
