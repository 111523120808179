/**
 * @generated SignedSource<<8369e48bcd8a09657765f0a5a1519432>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type getPopupQuery$variables = {};
export type getPopupQuery$data = {
  readonly popup: {
    readonly __typename: "PopupEntityResponse";
    readonly data: {
      readonly __typename: "PopupEntity";
      readonly attributes: {
        readonly description: string;
        readonly link: string | null;
        readonly title: string;
      };
      readonly id: string;
    } | null;
  };
} | null;
export type getPopupQuery = {
  response: getPopupQuery$data;
  variables: getPopupQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "link",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "getPopupQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": null,
          "concreteType": "PopupEntityResponse",
          "kind": "LinkedField",
          "name": "popup",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": (v0/*: any*/),
              "action": "LOG",
              "path": "popup.__typename"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PopupEntity",
              "kind": "LinkedField",
              "name": "data",
              "plural": false,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": (v0/*: any*/),
                  "action": "LOG",
                  "path": "popup.data.__typename"
                },
                {
                  "kind": "RequiredField",
                  "field": (v1/*: any*/),
                  "action": "LOG",
                  "path": "popup.data.id"
                },
                {
                  "kind": "RequiredField",
                  "field": {
                    "alias": null,
                    "args": null,
                    "concreteType": "Popup",
                    "kind": "LinkedField",
                    "name": "attributes",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "RequiredField",
                        "field": (v2/*: any*/),
                        "action": "LOG",
                        "path": "popup.data.attributes.title"
                      },
                      {
                        "kind": "RequiredField",
                        "field": (v3/*: any*/),
                        "action": "LOG",
                        "path": "popup.data.attributes.description"
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  "action": "LOG",
                  "path": "popup.data.attributes"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        "action": "LOG",
        "path": "popup"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "getPopupQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PopupEntityResponse",
        "kind": "LinkedField",
        "name": "popup",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PopupEntity",
            "kind": "LinkedField",
            "name": "data",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Popup",
                "kind": "LinkedField",
                "name": "attributes",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1790990d49d2b4171dc4ab825b2ba77f",
    "id": null,
    "metadata": {},
    "name": "getPopupQuery",
    "operationKind": "query",
    "text": "query getPopupQuery {\n  popup {\n    __typename\n    data {\n      __typename\n      id\n      attributes {\n        title\n        description\n        link\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "afe9280ce0cd495545ac46b7620da8d8";

export default node;
