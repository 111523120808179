import styled from "styled-components";

interface DisclosureContentProps {
  content: string;
}

const DisclosureContentWrapper = styled.div`
  margin-bottom: 40px;
  p,
  th,
  td {
    font-family: "Pretendard" !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    letter-spacing: -0.01em !important;

    ${({ theme }) => theme.media.mobile`
      font-weight: 300 !important;
      margin-bottom: 20px;
    `}
  }

  ol {
    list-style: number;
    margin-left: 16px;
    li {
      margin-bottom: 8px;
      p {
        margin-bottom: 8px !important;
      }
    }
  }

  figure {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 60% !important;
    }
  }

  table {
    border: 1px solid ${({ theme }) => theme.color.gray300};
    width: 100%;
  }
  thead {
    background-color: ${({ theme }) => theme.color.gray200};
  }
  thead > tr {
    border-bottom: 1px solid ${({ theme }) => theme.color.gray300};
  }
  tr {
    border-bottom: 1px solid ${({ theme }) => theme.color.gray500};
  }
  tr > td {
    border-right: 1px solid ${({ theme }) => theme.color.gray500};
  }
  tr > th {
    border-right: 1px solid ${({ theme }) => theme.color.gray300};
  }
  tr > td:last-child,
  th:last-child {
    border-right: none;
  }

  tbody > tr:last-child {
    border-bottom: none;
  }
  th,
  td {
    padding: 8px;
  }
`;

const DisclosureContent: React.FC<DisclosureContentProps> = (props) => {
  const { content } = props;
  return (
    <DisclosureContentWrapper dangerouslySetInnerHTML={{ __html: content }} />
  );
};

export default DisclosureContent;
