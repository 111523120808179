import { UploadFileEntity } from "@fragment/UploadFile";
import type { UploadFileEntity$key as UploadFileEntityType } from "@fragment/__generated__/UploadFileEntity.graphql";
import { ReactComponent as folderSVG } from "@assets/icon/folder.svg";
import { useFragment } from "react-relay";
import styled from "styled-components";

interface FileContainerProps {
  files: ReadonlyArray<UploadFileEntityType>;
  title: string;
}

interface FileItemProps {
  file: UploadFileEntityType;
}

const FileItemWrapper = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500 !important;
  color: ${({ theme }) => theme.color.deepBlue};
  ${({ theme }) => theme.fonts.body5}
  padding: 30px 40px;
  border: 1px solid ${({ theme }) => theme.color.gray100};
  box-shadow: 0px 0px 14px 0px #0000000d;
  border-radius: 15px;

  ${({ theme }) => theme.media.mobile`
    padding: 15px 20px;
    border-radius: 10px;
    ${theme.fonts.caption}
  `}

  &:hover {
    border: 1px solid ${({ theme }) => theme.color.deepBlue};
  }
  cursor: pointer;
`;

const FolderIcon = styled(folderSVG)`
  margin-right: 30px;

  ${({ theme }) => theme.media.tabletL`
    margin-right: 20px;
  `}
  ${({ theme }) => theme.media.tabletS`
    margin-right: 20px;
  `}
  ${({ theme }) => theme.media.mobile`
    display: none;
  `}
`;

const FileItem: React.FC<FileItemProps> = (props) => {
  const { file } = props;
  const data = useFragment(UploadFileEntity, file);
  return (
    data && (
      <FileItemWrapper href={data.attributes.url} target="_blank">
        <FolderIcon width="32px" />
        <div>{data?.attributes.name}</div>
      </FileItemWrapper>
    )
  );
};

const FilesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px 0px;
  margin-bottom: 50px;

  ${({ theme }) => theme.media.tabletL`
    margin-bottom: 30px;
  `}
  ${({ theme }) => theme.media.tabletS`
    margin-bottom: 30px;
  `}
  ${({ theme }) => theme.media.mobile`
    margin-bottom: 40px;
    gap: 10px 0px;
  `}
`;
const FileContainer: React.FC<FileContainerProps> = (props) => {
  const { files, title } = props;
  console.log(files);
  return (
    <FilesWrapper>
      {files.map((file, index) => (
        <FileItem key={`${title}-file-${index}`} file={file} />
      ))}
    </FilesWrapper>
  );
};
export default FileContainer;
