import { ButtonWrapper, FlexContainer } from "@components/common/etc";
import { BasePageRouteProps } from "@pages/const";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import styled, { StyledComponent } from "styled-components";
import HeaderLine from "@assets/image/header/line.png";

interface MenuItemWrapperProps {
  color: string;
}

interface MenuItemProps extends BasePageRouteProps, MenuItemWrapperProps {
  inHeader: boolean;
}

const ExternalMenuItemWrapper = styled.a<MenuItemWrapperProps>`
  ${({ theme }) => theme.fonts.subtitle4``}
  height: 30px;
  display: block;

  color: ${(props) => props.color};

  &:hover,
  &:focus {
    background-color: ${(props) => `${props.color}33`};
  }
  padding: 0px;

  span.current {
    border-bottom: 2px solid ${(props) => props.color};
  }
`;

const MenuItemWrapper = styled(Link)<MenuItemWrapperProps>`
  ${({ theme }) => theme.fonts.subtitle4``}
  height: 30px;
  display: block;

  color: ${(props) => props.color};

  &:hover,
  &:focus {
    background-color: ${(props) => `${props.color}33`};
  }

  span.current {
    border-bottom: 2px solid ${(props) => props.color};
  }
`;

const ExternalHeaderMenuItemWrapper = styled(ExternalMenuItemWrapper)`
  padding: 0px 10px;
  margin-left: 20px;

  border-radius: 4px;
  &:first-child {
    margin-left: 0px;
  }
`;

const HeaderMenuItemWrapper = styled(MenuItemWrapper)`
  padding: 0px 10px;
  margin-left: 20px;

  border-radius: 4px;
  &:first-child {
    margin-left: 0px;
  }
`;

export const HeaderMenuItem: React.FC<MenuItemProps> = (props) => {
  const { t } = useTranslation("common");
  const location = useLocation();
  const { name, href, color, target } = props;
  const { pathname } = location;

  if (props.href.includes("http")) {
    return (
      <ExternalHeaderMenuItemWrapper href={href} color={color} target={target}>
        <span className={href === pathname ? "current" : ""}>
          {t(`header.${name.toLowerCase()}`)}
        </span>
      </ExternalHeaderMenuItemWrapper>
    );
  }
  return (
    <HeaderMenuItemWrapper to={href} color={color} target={target}>
      <span className={href === pathname ? "current" : ""}>
        {t(`header.${name.toLowerCase()}`)}
      </span>
    </HeaderMenuItemWrapper>
  );
};

export const DrawerMenuItemWrapper: React.FC<MenuItemProps> = (props) => {
  const { t } = useTranslation("common");
  const location = useLocation();
  const { name, href, color, target } = props;
  const { pathname } = location;

  if (props.href.includes("http")) {
    return (
      <ExternalMenuItemWrapper href={href} color={color} target={target}>
        <span className={href === pathname ? "current" : ""}>
          {t(`header.${name.toLowerCase()}`)}
        </span>
      </ExternalMenuItemWrapper>
    );
  }
  return (
    <MenuItemWrapper to={href} color={color} target={target}>
      <span className={href === pathname ? "current" : ""}>
        {t(`header.${name.toLowerCase()}`)}
      </span>
    </MenuItemWrapper>
  );
};

interface LangMenuItemProps {
  color: string;
}

interface LangMenuProps extends LangMenuItemProps {
  ItemWrapper: StyledComponent<"button", any, LangMenuItemProps, never>;
}

const LangWrapper = styled(FlexContainer)<LangMenuItemProps>`
  align-items: center;
  .divider {
    height: 16px;
    margin: 0px 6px;
  }
  & .active {
    color: ${(props) => props.color};
  }
`;

export const LangItem = styled(ButtonWrapper)<LangMenuItemProps>`
  ${({ theme }) => theme.fonts.body4``}

  color: ${(props) => `${props.color}99`};

  &:hover,
  &:focus {
    background-color: ${(props) => `${props.color}33`};
  }
`;

export const LangMenu: React.FC<LangMenuProps> = (props) => {
  const { i18n } = useTranslation("main");

  /* 주된 내용이 아니여서 따로 설명을 첨부하지는 않았지만
   * changeLanguage 메소드를 통해 언어 변환을 하시면 됩니다.
   * 글로벌하게 적용되는 방식이라 따로 관리를 해주지 않아도 됩니다.
   */

  const { color, ItemWrapper } = props;

  const toggleLocales = useCallback(
    (locale: string) => {
      i18n.changeLanguage(locale);
      document.documentElement.lang = i18n.language.split("-")[0]!;
    },
    [i18n]
  );

  return (
    <LangWrapper color={color}>
      <ItemWrapper
        onClick={() => toggleLocales("ko-KR")}
        className={i18n.language === "ko-KR" ? "active" : ""}
        color={color}
      >
        KOR
      </ItemWrapper>
      <img src={HeaderLine} alt="lang-divider" className="divider" />
      <ItemWrapper
        onClick={() => toggleLocales("en-US")}
        className={i18n.language === "en-US" ? "active" : ""}
        color={color}
      >
        ENG
      </ItemWrapper>
    </LangWrapper>
  );
};
