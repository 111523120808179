import { ContentContainer } from "@components/common/etc";
import SuspensePage from "@components/common/suspense";
import DialogContainer from "@components/dialog";
import ResultContainer from "@components/disclosure/home/result";
import SearchContainer from "@components/disclosure/home/search";
import DisclosureTitle from "@components/disclosure/home/title";
import { FirstSection } from "@components/layout/banner";
import { Suspense, useState } from "react";
import styled from "styled-components";

const DisclosureSection = styled(FirstSection)`
  min-height: calc(100vh - 72px);
`;

const Content = styled(ContentContainer)`
  min-height: calc(100vh - 72px);
  display: flex;
  flex-direction: column;
`;

const DisclosureHome: React.FC = () => {
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);

  return (
    <>
      <DisclosureSection>
        <Content>
          <DisclosureTitle />
          <SearchContainer search={search} setSearch={setSearch} />
          <Suspense fallback={<SuspensePage />}>
            <ResultContainer search={search} page={page} setPage={setPage} />
          </Suspense>
        </Content>
      </DisclosureSection>
      <DialogContainer />
    </>
  );
};

export default DisclosureHome;
