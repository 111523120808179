import { useTranslation } from "react-i18next";
import styled from "styled-components";

const EmptyResultWrapper = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.fonts.subtitle2}

  ${({ theme }) => theme.media.tabletL`
    ${theme.fonts.subtitle3}
  `}

  ${({ theme }) => theme.media.tabletS`
    ${theme.fonts.subtitle3}
  `}

  ${({ theme }) => theme.media.mobile`
    ${theme.fonts.subtitle4}
  `}
`;

const EmptyResult: React.FC = () => {
  const { t } = useTranslation("disclosure");
  return <EmptyResultWrapper>{t("empty.result")}</EmptyResultWrapper>;
};
export default EmptyResult;
