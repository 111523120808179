import common from "./common.json";
import about from "./about.json";
import product from "./product.json";
import culture from "./culture.json";
import disclosure from "./disclosure.json";
import dialog from "./dialog.json";

export const lang = { common, about, product, culture, disclosure, dialog };
export const langResources = {
  common: typeof common,
  about: typeof about,
  product: typeof product,
  culture: typeof culture,
  disclosure: typeof disclosure,
  dialog: typeof dialog,
};
