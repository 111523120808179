import { FontType } from "@style/fonts";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface SectionLabelMarginBottomProps {
  desktop?: number;
  laptop?: number;
  tabletLarge?: number;
  tabletSmall?: number;
  mobile?: number;
}

interface SectionLabelWrapperProps {
  marginBottom?: SectionLabelMarginBottomProps;
}

const SectionLabelWrapper = styled.div<SectionLabelWrapperProps>`
  margin-bottom: ${({ marginBottom }) => marginBottom?.desktop ?? 80}px;

  ${({ theme, marginBottom }) => theme.media.laptop`
    margin-bottom: ${marginBottom?.laptop ?? 80}px;
  `}
  ${({ theme, marginBottom }) => theme.media.tabletL`
    margin-bottom: ${marginBottom?.tabletLarge ?? 80}px;
  `}
  ${({ theme, marginBottom }) => theme.media.tabletS`
    margin-bottom: ${marginBottom?.tabletSmall ?? 80}px;
  `}
  ${({ theme, marginBottom }) => theme.media.mobile`
    margin-bottom: ${marginBottom?.mobile ?? 80}px;
  `}
`;

interface SectionLabelFontType {
  desktop?: FontType;
  laptop?: FontType;
  tabletLarge?: FontType;
  tabletSmall?: FontType;
  mobile?: FontType;
}

interface SectionLabelTextProps {
  fontType?: SectionLabelFontType;
}

const SectionLabelText = styled.p<SectionLabelTextProps>`
  ${({ theme, fontType }) =>
    fontType?.desktop?.fontCss ?? theme.fonts.subtitle1}

  ${({ theme, fontType }) => theme.media.laptop`
    ${fontType?.laptop?.fontCss ?? theme.fonts.subtitle1}
  `}
  ${({ theme, fontType }) => theme.media.tabletL`
    ${fontType?.tabletLarge?.fontCss ?? theme.fonts.subtitle2}
  `}
  ${({ theme, fontType }) => theme.media.tabletS`
    ${fontType?.tabletSmall?.fontCss ?? theme.fonts.subtitle3}
  `}
  ${({ theme, fontType }) => theme.media.mobile`
    ${fontType?.mobile?.fontCss ?? theme.fonts.subtitle3}
  `}
`;

interface SectionLabelProps
  extends SectionLabelWrapperProps,
    SectionLabelTextProps {
  languageFileName: string;
  labelKey: string;
}

const SectionLabel: React.FC<SectionLabelProps> = (props) => {
  const { marginBottom, fontType, labelKey, languageFileName } = props;
  const { t } = useTranslation(languageFileName);

  return (
    <SectionLabelWrapper marginBottom={marginBottom}>
      <SectionLabelText fontType={fontType}>{t(labelKey)}</SectionLabelText>
    </SectionLabelWrapper>
  );
};

export default SectionLabel;
