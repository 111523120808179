import { BackQuoteArgs } from "@style/const";
import { css, CSSProp } from "styled-components";

interface DeviceSizeProps {
    name: string,
    mainWidth: number
    max?: number,
    min?: number,
}


class DeviceSize implements DeviceSizeProps {
    /** 디바이스 사이즈 객체 
     * @param {string} name 이름
     * @param {number} mainWidth main의 width
     * @param {number?} max 최대 넓이, undefine의 경우 무한대까지
     * @param {number} min 최소 넓이, undefine의 경우 0까지
    */
    constructor(public name: string, public mainWidth: number, public max?: number, public min?: number) {
        if (max === undefined && min === undefined) {
            throw new Error("max 또는 min중 최소 하나는 설정 되어 있어야 합니다.")
        }
    }

    get _maxPx() {
        if (this.max === undefined) {
            return '';
        } else {
            return `(max-width: ${this.max - 1}px)`
        }
    }

    get _minPx() {
        if (this.min === undefined) {
            return '';
        } else {
            return `(min-width: ${this.min}px)`
        }
    }

    get minPx() {
        if (this.min === undefined) {
            return '';
        } else {
            return ` and (min-width: ${this.min}px)`
        }
    }

    get mediaQueryWithScreen() {
        return `@media screen and ${this.mediaQuery}`;

    }

    get mediaQuery() {
        if (this._maxPx.length > 0) {
            return `${this._maxPx}${this.minPx}`;
        } else {
            return `${this._maxPx}${this._minPx}`;
        }
    }
}

export const mediaSizes = {
    mobile: new DeviceSize("mobile", 328, 600, undefined),
    tabletS: new DeviceSize("tabletSmall", 536, 950, 600),
    tabletL: new DeviceSize("tabletLarge", 857, 1240, 950),
    laptop: new DeviceSize("laptop", 880, 1440, 1240),
    desktop: new DeviceSize("desktop", 1128, undefined, 1440)
};


type mediaKeys = keyof typeof mediaSizes;

export default Object.entries(mediaSizes).reduce((acc, entry) => {
    acc[entry[0] as mediaKeys] = (literals: TemplateStringsArray, ...args: BackQuoteArgs): CSSProp =>
        css`
      ${entry[1].mediaQueryWithScreen} {
        ${css(literals, ...args)}
      }
    `;
    return acc;
}, {} as Record<
    keyof typeof mediaSizes,
    (l: TemplateStringsArray, ...p: BackQuoteArgs) => CSSProp
>);