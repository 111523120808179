/**
 * @generated SignedSource<<b2f786fad57107efda7584582303b1c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UploadFileRelationResponseCollection$data = {
  readonly __typename: "UploadFileRelationResponseCollection";
  readonly data: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"UploadFileEntity">;
  }>;
  readonly " $fragmentType": "UploadFileRelationResponseCollection";
} | null;
export type UploadFileRelationResponseCollection$key = {
  readonly " $data"?: UploadFileRelationResponseCollection$data;
  readonly " $fragmentSpreads": FragmentRefs<"UploadFileRelationResponseCollection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UploadFileRelationResponseCollection",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      "action": "LOG",
      "path": "__typename"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "UploadFileEntity",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UploadFileEntity"
          }
        ],
        "storageKey": null
      },
      "action": "LOG",
      "path": "data"
    }
  ],
  "type": "UploadFileRelationResponseCollection",
  "abstractKey": null
};

(node as any).hash = "0cfefe7512ffac2c7dc2be526af73c40";

export default node;
