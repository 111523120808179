import InquiriesBannerImage from "@assets/image/inquiries";
import Layout from "@components/layout";
import PageBanner, { FirstSectionWithBanner } from "@components/layout/banner";
import {
  InquiriesSectionContent,
  InquiriesSectionProps,
} from "@components/about/Inquiries";
import { PageProps } from "@pages/const";
import styled from "styled-components";
import { theme } from "@style/theme";
import DialogContainer from "@components/dialog";

const InquiriesSectionWrapper = styled(
  FirstSectionWithBanner
)<InquiriesSectionProps>`
  color: ${({ fontcolor }) => fontcolor ?? "inherit"};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ?? "transparent"};
`;

const InquiriesSection: React.FC<InquiriesSectionProps> = (props) => {
  const { fontcolor, backgroundColor, hovercolor } = props;
  return (
    <InquiriesSectionWrapper
      fontcolor={fontcolor}
      backgroundColor={backgroundColor}
    >
      <InquiriesSectionContent fontcolor={fontcolor} hovercolor={hovercolor} />
    </InquiriesSectionWrapper>
  );
};

const InquiriesPage: React.FC<PageProps> = (props) => {
  const { title } = props;
  return (
    <Layout title={title}>
      <PageBanner
        image={InquiriesBannerImage}
        size={"initial"}
        bgcolor={theme.color.deepBlue}
      />
      <InquiriesSection />
      <DialogContainer />
    </Layout>
  );
};

export default InquiriesPage;
