import DisclosureContent from "@components/disclosure/detail/content";
import FileContainer from "@components/disclosure/detail/files";
import TitleContainer from "@components/disclosure/detail/title";
import type { getDisclosureQuery as getDisclosureQueryType } from "@query/__generated__/getDisclosureQuery.graphql";
import { GetDisclosureQuery } from "@query/get";
import { useLazyLoadQuery } from "react-relay";
import styled from "styled-components";

export interface DisclosureDetailProps {
  disclosureId: string;
}

const DisclosureDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;

  border-bottom: 1px solid ${({ theme }) => theme.color.gray500};
  margin-bottom: 50px;

  ${({ theme }) => theme.media.tabletL`
    margin-bottom: 40px;
  `}
  ${({ theme }) => theme.media.tabletS`
    margin-bottom: 40px;
  `}
  ${({ theme }) => theme.media.mobile`
    border-bottom: none;
    margin-bottom: 0px;
  `}
`;

const DisclosureDetailMain: React.FC<DisclosureDetailProps> = (props) => {
  const { disclosureId } = props;
  const data = useLazyLoadQuery<getDisclosureQueryType>(
    GetDisclosureQuery,
    {
      id: disclosureId,
    },
    {
      fetchPolicy: "network-only",
    }
  );
  return (
    data && (
      <DisclosureDetailWrapper>
        <TitleContainer
          title={data.disclosure.data.attributes.title}
          published_date={data.disclosure.data.attributes.published_date}
          type={data.disclosure.data.attributes.type}
        />
        <DisclosureContent content={data.disclosure.data.attributes.content} />
        {data.disclosure.data.attributes.files.data.length > 0 && (
          <FileContainer
            files={data.disclosure.data.attributes.files.data}
            title={data.disclosure.data.attributes.title}
          />
        )}
      </DisclosureDetailWrapper>
    )
  );
};
export default DisclosureDetailMain;
