import { ImageSetProps } from "@components/common/etc";
import Desktop from "./desktop.png";
import Laptop from "./laptop.png";
import TabletLarge from "./tabletLarge.png";
import TabletSmall from "./tabletSmall.png";
import Mobile from "./mobile.png";

const OllyFullImage: ImageSetProps = {
    desktop: {src:Desktop, height: 770, width: 522,},
    laptop: {src:Laptop, height: 597, width: 428,},
    tabletL: {src:TabletLarge, height: 582, width: 417,},
    tabletS: {src:TabletSmall, height: 747, width: 536,},
    mobile: {src:Mobile, height: 455, width: 328,},
    alt: "olly-full-image"
}

export default OllyFullImage