import Layout from "@components/layout";
import PageBanner from "@components/layout/banner";
import { PageProps } from "@pages/const";
import ProductBannerImage from "@assets/image/product/banner";
import FinterSection from "@components/product/finter";
import Service from "@components/product/service";
import DialogContainer from "@components/dialog";

const ProductPage: React.FC<PageProps> = (props) => {
  const { title } = props;
  return (
    <Layout title={title}>
      <PageBanner image={ProductBannerImage} />
      <FinterSection />
      <Service.Olly />
      {/* <Service.Rabit /> */}
      <DialogContainer />
    </Layout>
  );
};

export default ProductPage;
