import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface TitleContainerProps {
  title: string;
  published_date: string;
  type: string;
}

const TitleWrapper = styled.div`
  margin: 50px 0px;
  padding: 30px 0px;
  border-top: 1px solid ${({ theme }) => theme.color.deepBlue};
  border-bottom: 1px solid ${({ theme }) => theme.color.gray500};

  ${({ theme }) => theme.media.tabletL`
    margin: 30px 0px;
  `}
  ${({ theme }) => theme.media.tabletS`
    margin: 30px 0px;
  `}
  ${({ theme }) => theme.media.mobile`
    padding: 20px 0px;
    margin: 20px 0px;
  `}
`;

const CompanyText = styled.p`
  color: ${({ theme }) => theme.color.gray500} !important;
  font-weight: 500 !important;
  ${({ theme }) => theme.fonts.body3}
  margin-bottom: 12px;

  ${({ theme }) => theme.media.tabletL`
    ${theme.fonts.body5}
  `}
  ${({ theme }) => theme.media.tabletS`
    ${theme.fonts.body5}
  `}
  ${({ theme }) => theme.media.mobile`
    ${theme.fonts.caption}
    margin-bottom: 10px;
  `}
`;

const TitleTextBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const TitleText = styled.h1`
  ${({ theme }) => theme.fonts.subtitle2}

  ${({ theme }) => theme.media.tabletL`
    ${theme.fonts.subtitle4}
  `}
  ${({ theme }) => theme.media.tabletS`
    ${theme.fonts.subtitle4}
  `}
  ${({ theme }) => theme.media.mobile`
    ${theme.fonts.subtitle5}
  `}
`;

const DateText = styled.span`
  color: ${({ theme }) => theme.color.gray500} !important;
  ${({ theme }) => theme.fonts.body3}

  ${({ theme }) => theme.media.mobile`
    ${theme.fonts.caption}
  `}
`;

const TitleContainer: React.FC<TitleContainerProps> = (props) => {
  const { title, published_date, type } = props;
  const { t } = useTranslation("disclosure");
  return (
    <TitleWrapper>
      <CompanyText>{t("quantit")}</CompanyText>
      <TitleTextBox>
        <TitleText>{`[${t(type)}] ${title}`}</TitleText>
        <DateText>{published_date}</DateText>
      </TitleTextBox>
    </TitleWrapper>
  );
};
export default TitleContainer;
