import { DeviceMediaQuery } from "@/utils";
import SectionLabel from "@components/common/label";

import {
  ButtonWrapper,
  ContentContainer,
  ImageContainer,
  LanguageSwitcher,
} from "@components/common/etc";
import Headline from "@components/common/headline";
import Subtitle from "@components/common/subtitle";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import TechImage from "@assets/image/about/tech";
import { Link } from "react-router-dom";

const SectionTitleWrapper = styled.div`
  margin-bottom: 80px;
`;

const SectionTitle = () => {
  const mediaQuery = DeviceMediaQuery();
  const { t } = useTranslation("about");

  return (
    <SectionTitleWrapper>
      {mediaQuery.isDesktop && <Headline.Four>{t("tech.title")}</Headline.Four>}
      {mediaQuery.isTablet && <Headline.Five>{t("tech.title")}</Headline.Five>}
      {mediaQuery.isMobile && <Subtitle.One>{t("tech.title")}</Subtitle.One>}
    </SectionTitleWrapper>
  );
};

const SectionWrapper = styled.section`
  background-color: ${({ theme }) => theme.color.deepBlue};
`;

const BodyTextWrapper = styled.p`
  ${({ theme }) => theme.fonts.body3}

  ${({ theme }) => theme.media.mobile`
    ${theme.fonts.body5}
  `}
`;

const KoreanContainer = () => {
  const { t } = useTranslation("about");

  return (
    <BodyTextWrapper>
      {t("tech.body1")}
      <br />
      {t("tech.body2")}
      <br />
      {t("tech.body3")}
    </BodyTextWrapper>
  );
};

const EnglishBodyText = () => {
  const { t } = useTranslation("about");

  return <BodyTextWrapper>{t("tech.body")}</BodyTextWrapper>;
};

const ContentWrapper = styled.div`
  margin: 80px 0px;
`;
const ViewButtonWrapper = styled(Link)`
  display: flex;
  justify-content: center;
  color: inherit;
`;
const ViewButton = styled(ButtonWrapper)`
  width: 150px;
  height: 56px;
  text-align: center;
  border: 2px solid #ffffff;

  &:hover,
  &:focus ${ButtonWrapper} {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const TechnologySection = () => {
  const GoTechPage = () => {
    // TODO 더보기 누르면 제품소개로 이동
    console.log("// TODO 더보기 누르면 제품소개로 이동");
  };

  const { t } = useTranslation("about");
  return (
    <SectionWrapper>
      <ContentContainer color={"#FFFFFF"}>
        <SectionLabel languageFileName="about" labelKey="tech.label" />
        <SectionTitle />
        <LanguageSwitcher
          target="about"
          KoreanContainer={KoreanContainer}
          EnglishContainer={EnglishBodyText}
        />
        <ContentWrapper>
          <ImageContainer image={TechImage} />
        </ContentWrapper>
        <ViewButtonWrapper to="/product">
          <ViewButton onClick={GoTechPage}>{t("tech.button")}</ViewButton>
        </ViewButtonWrapper>
      </ContentContainer>
    </SectionWrapper>
  );
};

export default TechnologySection;
