import { ImageSetProps } from "@components/common/etc";
import Desktop from "./desktop.png";
import Laptop from "./laptop.png";
import TabletLarge from "./tabletLarge.png";
import TabletSmall from "./tabletSmall.png";
import Mobile from "./mobile.png";

const ProductBannerImage: ImageSetProps = {
    desktop: {src:Desktop, height:184},
    laptop:{src:Laptop, height: 160},
    tabletL: {src:TabletLarge, height:160},
    tabletS: {src:TabletSmall, height: 96},
    mobile: {src:Mobile, height:80},
    alt: "product-banner-image"
}

export default ProductBannerImage