import { Route, Routes } from "react-router-dom";
import GlobalStyles from "@style/global";
import { ThemeProvider } from "styled-components";
import { theme } from "@style/theme";
import NotFoundPage from "@pages/404";
import HomePage from "@pages/home";
import ProductPage from "@pages/product";
import CulturePage from "@pages/culture";
import InquiriesPage from "@pages/inquiries";
import DisclosurePage from "@pages/disclosure";
import DisclosureHome from "@pages/disclosure/outlet/home";
import DisclosureDetail from "@pages/disclosure/outlet/detail";

function App() {
  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<HomePage title="Home" />} />
          <Route path="/product" element={<ProductPage title="Product" />} />
          <Route path="/culture" element={<CulturePage title="Culture" />} />
          <Route
            path="/inquiries"
            element={<InquiriesPage title="Inquiries" />}
          />
          <Route
            path="/disclosure"
            element={<DisclosurePage title="Disclosure" />}
          >
            <Route index path="" element={<DisclosureHome />} />
            <Route path=":disclosureId" element={<DisclosureDetail />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </ThemeProvider>
    </>
  );
}

export default App;
