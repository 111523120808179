import { CheckMediaQuery, DeviceMediaQuery } from "@/utils";
import SectionLabel from "@components/common/label";
import { ContentContainer, LanguageSwitcher } from "@components/common/etc";
import Headline from "@components/common/headline";
import Subtitle from "@components/common/subtitle";
import { SubtitleType } from "@style/fonts";
import { useTranslation } from "react-i18next";

const EnglishBodyText = () => {
  const mediaQuery = DeviceMediaQuery();
  const { t } = useTranslation("about");

  return (
    <>
      {mediaQuery.isDesktop && (
        <Headline.Four>{t("mission.body")}</Headline.Four>
      )}
      {mediaQuery.isTablet && (
        <Headline.Five>{t("mission.body")}</Headline.Five>
      )}
      {mediaQuery.isMobile && <Subtitle.Two>{t("mission.body")}</Subtitle.Two>}
    </>
  );
};

const KoreanBodyTextOne = () => {
  const { t } = useTranslation("about");
  return (
    <>
      {`${t("mission.body1")} ${t("mission.body2")}`}
      <br />
      {t("mission.body3")}
      <br />
      {t("mission.body4")}
      <br />
      {t("mission.body5")}
    </>
  );
};

const KoreanBodyTextTwo = () => {
  const { t } = useTranslation("about");
  return (
    <>
      {t("mission.body1")}
      <br />
      {t("mission.body2")}
      <br />
      {t("mission.body3")}
      <br />
      {t("mission.body4")}
      <br />
      {t("mission.body5")}
    </>
  );
};

const KoreanBodyContainer = () => {
  const mediaQuery = CheckMediaQuery();

  return (
    <>
      {mediaQuery.isDesktop && (
        <Headline.Four>
          <KoreanBodyTextOne />
        </Headline.Four>
      )}
      {mediaQuery.isLaptop && (
        <Headline.Four>
          <KoreanBodyTextTwo />
        </Headline.Four>
      )}
      {mediaQuery.isTabletLarge && (
        <Headline.Five>
          <KoreanBodyTextOne />
        </Headline.Five>
      )}
      {mediaQuery.isTabletSmall && (
        <Headline.Five>
          <KoreanBodyTextTwo />
        </Headline.Five>
      )}
      {mediaQuery.isMobile && (
        <Subtitle.Two>
          <KoreanBodyTextTwo />
        </Subtitle.Two>
      )}
    </>
  );
};

const MissionSection = () => {
  return (
    <section>
      <ContentContainer padding={{ tabletSmall: 160, mobile: 160 }}>
        <SectionLabel
          languageFileName="about"
          labelKey="mission.label"
          fontType={{ tabletSmall: SubtitleType.subtitle2 }}
        />
        <LanguageSwitcher
          target="about"
          KoreanContainer={KoreanBodyContainer}
          EnglishContainer={EnglishBodyText}
        />
      </ContentContainer>
    </section>
  );
};

export default MissionSection;
