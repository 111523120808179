import { Dispatch, SetStateAction } from "react";
import styled from "styled-components";

import DrawerButton from "@assets/image/drawer/drawer-x.png";
import { ButtonWrapper, FlexContainer } from "@components/common/etc";
import { MenuList } from "@pages/const";
import {
  LangItem,
  LangMenu,
  DrawerMenuItemWrapper,
} from "@components/layout/menuItem";
import { theme } from "@style/theme";

interface DrawerWrapperProps {
  openDrawer: boolean;
}

interface DrawerProps extends DrawerWrapperProps {
  setOpenDrawer: Dispatch<SetStateAction<boolean>>;
}

const DrawerWrapper = styled.nav<DrawerWrapperProps>`
  z-index: 200;
  display: ${(props) => (props.openDrawer ? "flex" : "none")};
  flex-direction: column;
  align-items: end;

  background-color: white;
  width: 172px;
  height: 100vh;
  position: fixed;
  right: 0;
  padding-left: 16px;
  padding-top: 12px;
`;

const DrawerButtonWrapper = styled(ButtonWrapper)`
  height: 48px;
  width: 48px;

  img {
    height: 48px;
    width: 48px;
  }

  &:hover,
  &:focus ${ButtonWrapper} {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const MenuWrapper = styled.ul`
  margin: 24px 0px;
  width: 100%;

  li {
    margin-bottom: 24px;
    margin-right: 20px;
    height: 30px;
  }

  li:last-child {
    margin-bottom: 0px;
  }
`;

const DrawerLangMenuWrapper = styled(FlexContainer)`
  width: 100%;
`;

const DrawerLangItem = styled(LangItem)`
  margin: 0px 10px;

  &:first-child {
    margin-left: 0px;
  }

  &:last-child {
    margin-right: 0px;
  }
`;

const Drawer: React.FC<DrawerProps> = (props) => {
  const { openDrawer, setOpenDrawer } = props;

  return (
    <DrawerWrapper openDrawer={openDrawer}>
      <DrawerButtonWrapper onClick={() => setOpenDrawer(false)}>
        <img src={DrawerButton} alt="drawer-x-button" />
      </DrawerButtonWrapper>
      <MenuWrapper>
        <MenuWrapper>
          {MenuList.map((menu) => (
            <li key={menu.name}>
              <DrawerMenuItemWrapper
                name={menu.name}
                inHeader={false}
                href={menu.href}
                target={menu.target}
                color={theme.color.deepBlue}
              />
            </li>
          ))}
        </MenuWrapper>
      </MenuWrapper>
      <DrawerLangMenuWrapper>
        <LangMenu
          color={theme.color.deepBlue}
          ItemWrapper={DrawerLangItem}
        ></LangMenu>
      </DrawerLangMenuWrapper>
    </DrawerWrapper>
  );
};

export default Drawer;
