import { DeviceMediaQuery } from "@/utils";
import ProductTechImage from "@assets/image/product/finter/finter";
import BodyText from "@components/common/bodyText";
import {
  ImageContainer,
  LanguageSwitcher,
  MediaWrapper,
} from "@components/common/etc";
import Headline from "@components/common/headline";
import SectionLabel from "@components/common/label";
import Subtitle from "@components/common/subtitle";
import { FirstSectionWithBanner } from "@components/layout/banner";
import FinterInfoPanel from "@components/product/finter/value";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const FinterSectionWrapper = styled(FirstSectionWithBanner)`
  background-color: ${({ theme }) => theme.color.deepBlue};
  color: white;
`;

const ContentContainer = styled(MediaWrapper)`
  ${({ theme }) => theme.media.desktop`
    padding-top: 168px;
    padding-bottom: 40px;
  `}
  ${({ theme }) => theme.media.laptop`
    padding-top: 160px;
    padding-bottom: 24px;
  `}
  ${({ theme }) => theme.media.tabletL`
    padding-top: 160px;
    padding-bottom: 24px;
  `}
  ${({ theme }) => theme.media.tabletS`
    padding-top: 96px;
    padding-bottom: 24px;
  `}
  ${({ theme }) => theme.media.mobile`
    padding-top: 96px;
    padding-bottom: 24px;
  `}
`;

const SectionTextWrapper = styled.div`
  margin-bottom: 80px;
`;

const SectionTitle = () => {
  const mediaQuery = DeviceMediaQuery();
  const { t } = useTranslation("product");
  return (
    <SectionTextWrapper>
      {mediaQuery.isDesktop && (
        <Headline.Four>{t("finter.title")}</Headline.Four>
      )}
      {mediaQuery.isTablet && (
        <Headline.Four>{t("finter.title")}</Headline.Four>
      )}
      {mediaQuery.isMobile && <Subtitle.One>{t("finter.title")}</Subtitle.One>}
    </SectionTextWrapper>
  );
};

const SectionBody = () => {
  const { t } = useTranslation("product");
  return (
    <SectionTextWrapper>
      <LanguageSwitcher
        target="product"
        KoreanContainer={() => (
          <BodyText.Three>
            {t("finter.body1")}
            <br />
            {t("finter.body2")}
          </BodyText.Three>
        )}
        EnglishContainer={() => (
          <BodyText.Three>{t("finter.body")}</BodyText.Three>
        )}
      />
    </SectionTextWrapper>
  );
};

const FinterSection = () => {
  return (
    <FinterSectionWrapper>
      <ContentContainer>
        <SectionLabel
          labelKey="finter.label"
          languageFileName="product"
        ></SectionLabel>
        <SectionTitle />
        <SectionBody />
        <ImageContainer image={ProductTechImage} />
        <FinterInfoPanel/>
      </ContentContainer>
    </FinterSectionWrapper>
  );
};

export default FinterSection;
