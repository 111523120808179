import CultureBannerImage from "@assets/image/culture";
import JobsSection from "@components/culture/jobs";
import LifeSection from "@components/culture/life";
import SupportSection from "@components/culture/support";
import DialogContainer from "@components/dialog";
import Layout from "@components/layout";
import PageBanner from "@components/layout/banner";
import { PageProps } from "@pages/const";

const CulturePage: React.FC<PageProps> = (props) => {
  const { title } = props;
  return (
    <Layout title={title}>
      <PageBanner image={CultureBannerImage} />
      <LifeSection.One />
      <LifeSection.Two />
      <LifeSection.Three />
      <SupportSection />
      <JobsSection />
      <DialogContainer />
    </Layout>
  );
};

export default CulturePage;
