import { ImageSetProps } from "@components/common/etc";
import Desktop from "./desktop.png";
import Laptop from "./laptop.png";
import TabletLarge from "./tabletLarge.png";
import TabletSmall from "./tabletSmall.png";
import Mobile from "./mobile.png";

const RabitFullImage: ImageSetProps = {
    desktop: {src:Desktop, height: 615.5, width: 552.31,},
    laptop: {src:Laptop, height: 480, width: 432,},
    tabletL: {src:TabletLarge, height: 730, width: 857,},
    tabletS: {src:TabletSmall, height: 464, width: 536,},
    mobile: {src:Mobile, height: 360, width: 328,},
    alt: "rabit-full-image"
}

export default RabitFullImage