import { LanguageSwitcher } from "@components/common/etc";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const HeadlineWrapper = styled.div`
  padding-bottom: 80px;
  padding-top: 80px;

  display: flex;
  justify-content: center;
  color: white;

  ${({ theme }) => theme.media.laptop`
    padding-top: 56px;
  `}
  ${({ theme }) => theme.media.tabletL`
    padding-top: 60px;
  `}
  ${({ theme }) => theme.media.tabletS`
    padding-top: 40px;
  `}
  ${({ theme }) => theme.media.mobile`
    padding-top: 40px;
  `}
`;

const HeadLineText = styled.p`
  ${({ theme }) => theme.fonts.h3}
  text-align: center;
  
  ${({ theme }) => theme.media.laptop`
    ${theme.fonts.h3}
    text-align: center;
  `}
  ${({ theme }) => theme.media.tabletL`
    ${theme.fonts.h5}
    text-align: center;
  `}
  ${({ theme }) => theme.media.tabletS`
    ${theme.fonts.h5}
    text-align: center;
  `}
  ${({ theme }) => theme.media.mobile`
    ${theme.fonts.subtitle1}
    text-align: center;
  `}
`;

const HeadLineContent: React.FC = () => {
  const { t } = useTranslation("about");
  return (
    <HeadlineWrapper>
      <LanguageSwitcher
        target="about"
        KoreanContainer={() => <HeadLineText>{t("main.title")}</HeadLineText>}
        EnglishContainer={() => (
          <HeadLineText>
            {t("main.title1")}
            <br />
            {t("main.title2")}
          </HeadLineText>
        )}
      />
      <HeadLineText />
    </HeadlineWrapper>
  );
};

export default HeadLineContent;
