import Layout from "@components/layout";
import { PageProps } from "@pages/const";
import MainSection from "@components/about/main";
import MissionSection from "@components/about/mission";
import TechnologySection from "@components/about/tech";
import HistorySection from "@components/about/history";
import RelativeComSection from "@components/about/relative";
import CultureSection from "@components/about/culture";
import InquiriesSection from "@components/about/Inquiries";
import { theme } from "@style/theme";
import DialogContainer from "@components/dialog";
import Services from "@components/product/service";

const HomePage: React.FC<PageProps> = (props) => {
  const { title } = props;
  return (
    <Layout title={title}>
      <MainSection />
      <MissionSection />
      <TechnologySection />
      <Services.Olly />
      <HistorySection />
      <RelativeComSection />
      <CultureSection />
      <InquiriesSection
        fontcolor={"#FFFFFF"}
        backgroundColor={theme.color.deepBlue}
        hovercolor={"#FFFFFF"}
      />
      <DialogContainer />
    </Layout>
  );
};

export default HomePage;
