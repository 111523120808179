import graphql from "babel-plugin-relay/macro";

export const DisclosureEntity = graphql`
  fragment DisclosureEntity on DisclosureEntity {
    __typename @required(action: LOG)
    id @required(action: LOG)
    attributes @required(action: LOG) {
      ...Disclosure @relay(mask: false)
    }
  }
`;

export const Disclosure = graphql`
  fragment Disclosure on Disclosure{
    __typename @required(action: LOG)
    title @required(action: LOG)
    type @required(action: LOG)
    content @required(action: LOG)
    published_date @required(action: LOG)
    files @required(action: LOG) {
      ...UploadFileRelationResponseCollection @relay(mask: false)
    }
  }
`;