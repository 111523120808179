import { ContentContainer } from "@components/common/etc";
import Layout from "@components/layout";
import { FirstSection } from "@components/layout/banner";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const NotFoundPageSection = styled(FirstSection)`
  min-height: calc(100vh - 72px);
`;

const ContentWrapper = styled(ContentContainer)`
  min-height: calc(100vh - 72px);
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const LabelText = styled.p`
  margin-top: 60px;
  ${({ theme }) => theme.fonts.subtitle1}
  color: #a299ac !important;
  font-weight: 600 !important;
  line-height: 64px !important;
  letter-spacing: 0.1em !important;

  ${({ theme }) => theme.media.tabletL`
    margin-top: 40px;
    ${theme.fonts.subtitle2}
  `}
  ${({ theme }) => theme.media.tabletS`
    margin-top: 30px;
    ${theme.fonts.subtitle2}
  `}
  ${({ theme }) => theme.media.mobile`
    margin-top: 0px;
    ${theme.fonts.subtitle3}
  `}
`;

const TitleText = styled.h3`
  margin-bottom: 40px;
  ${({ theme }) => theme.fonts.h3}
  line-height: 1 !important;
  letter-spacing: -0.022em !important;

  ${({ theme }) => theme.media.tabletL`
    ${theme.fonts.h5}
  `}
  ${({ theme }) => theme.media.tabletS`
    ${theme.fonts.h5}
  `}
  ${({ theme }) => theme.media.mobile`
    ${theme.fonts.subtitle1}
  `}
`;

const BodyText = styled.p`
  color: #727272;
  ${({ theme }) => theme.fonts.body3}
  letter-spacing: -.022em !important;

  ${({ theme }) => theme.media.tabletS`
    ${theme.fonts.body5}
  `}
  ${({ theme }) => theme.media.mobile`
    ${theme.fonts.body4}
  `}
`;

const NotFoundPage = () => {
  const { t } = useTranslation("common");
  return (
    <Layout title={"Not Found Page"}>
      <NotFoundPageSection>
        <ContentWrapper>
          <LabelText>404</LabelText>
          <TitleText>Page Not Found.</TitleText>
          <BodyText>
            {t("notfound.body1")}
            <br />
            {t("notfound.body2")}
          </BodyText>
        </ContentWrapper>
      </NotFoundPageSection>
    </Layout>
  );
};

export default NotFoundPage;
