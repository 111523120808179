import { mediaSizes } from "@style/media";
import { useMediaQuery } from "react-responsive";

export const firstUpperCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

interface MediaQueryResult {
  isDesktop: boolean;
  isLaptop: boolean;
  isTabletLarge: boolean;
  isTabletSmall: boolean;
  isMobile: boolean;
}

export const CheckMediaQuery = (): MediaQueryResult => {
  return (
    {
      isDesktop: useMediaQuery({
        query: mediaSizes.desktop.mediaQuery,
      }),
      isLaptop: useMediaQuery({
        query: mediaSizes.laptop.mediaQuery,
      }),
      isTabletLarge: useMediaQuery({
        query: mediaSizes.tabletL.mediaQuery,
      }),
      isTabletSmall: useMediaQuery({
        query: mediaSizes.tabletS.mediaQuery,
      }),
      isMobile: useMediaQuery({
        query: mediaSizes.mobile.mediaQuery,
      }),
    }
  )
}

interface DeviceMediaQueryResult {
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
}

export const DeviceMediaQuery = (): DeviceMediaQueryResult => {
  return (
    {
      isDesktop: useMediaQuery({
        query: `${mediaSizes.laptop._minPx}`,
      }),
      isTablet: useMediaQuery({
        query: `${mediaSizes.tabletL._maxPx} and ${mediaSizes.tabletS._minPx}`,
      }),
      isMobile: useMediaQuery({
        query: mediaSizes.mobile.mediaQuery,
      }),
    }
  )
}