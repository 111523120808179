import HistoryData from "@components/about/history/const";
import YearPanel from "@components/about/history/items";
import SectionLabel from "@components/common/label";
import { ContentContainer, FlexContainer } from "@components/common/etc";
import styled from "styled-components";

const HistorySectionWrapper = styled.section`
  background-color: ${({ theme }) => theme.color.gray100};
`;

const HistoryContainer = styled(FlexContainer)`
  flex-direction: column;
`;

const HistorySection = () => {
  return (
    <HistorySectionWrapper>
      <ContentContainer>
        <SectionLabel languageFileName="about" labelKey="history.label" />
        <HistoryContainer>
          {HistoryData.map((yearData) => (
            <YearPanel key={yearData.year} data={yearData} />
          ))}
        </HistoryContainer>
      </ContentContainer>
    </HistorySectionWrapper>
  );
};

export default HistorySection;
