import SectionLabel from "@components/common/label";
import CompanyCard from "@components/about/relative/card";
import RelativeComData from "@components/about/relative/const ";
import { ContentContainer, FlexContainer } from "@components/common/etc";
import styled from "styled-components";

const CardContainer = styled(FlexContainer)`
  flex-direction: column;
`;

const RelativeComSection = () => {
  return (
    <section>
      <ContentContainer>
        <SectionLabel languageFileName="about" labelKey="relative.label" />
        <CardContainer>
          {RelativeComData.map((data) => (
            <CompanyCard key={data.descriptionKey} data={data} />
          ))}
        </CardContainer>
      </ContentContainer>
    </section>
  );
};

export default RelativeComSection;
