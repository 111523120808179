import styled from "styled-components";

import HeaderLogo from "@assets/image/header/logo.png";
import Hamburger from "@assets/image/header/button.png";

import {
  MediaWrapper,
  FlexContainer,
  ButtonWrapper,
} from "@components/common/etc";
import React, { Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";
import { MenuList } from "@pages/const";
import {
  LangItem,
  LangMenu,
  HeaderMenuItem,
} from "@components/layout/menuItem";

const HeaderWrapper = styled.header`
  color: white;
  height: 72px;
  width: 100%;
  background-color: ${({ theme }) => theme.color.deepBlue};
  position: fixed;
  z-index: 100;
`;

const ContentContainer = styled(MediaWrapper)`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo {
    height: 44px;
  }
`;

const MenuContainer = styled(FlexContainer)`
  ${({ theme }) => theme.media.tabletL`
    display: none
  `}
  ${({ theme }) => theme.media.tabletS`
    display: none
  `}
  ${({ theme }) => theme.media.mobile`
    display: none
  `}
`;

const MenuWrapper = styled(FlexContainer)`
  margin-right: 60px;
`;

const HamburgerButton = styled(ButtonWrapper)`
  height: 48px;
  width: 48px;

  img {
    height: 48px;
    width: 48px;
  }

  &:hover,
  &:focus ${ButtonWrapper} {
    background-color: rgba(255, 255, 255, 0.2);
  }

  ${({ theme }) => theme.media.desktop`
    display: none
  `}
  ${({ theme }) => theme.media.laptop`
    display: none
  `}
`;

const Logo: React.FC = () => {
  return (
    <Link to="/">
      <ButtonWrapper>
        <img src={HeaderLogo} alt="header-logo" className="logo" />
      </ButtonWrapper>
    </Link>
  );
};

const HeaderLangItem = styled(LangItem)`
  padding: 0px 10px;
  border-radius: 4px;
`;

interface HeaderProps {
  setOpenDrawer: Dispatch<SetStateAction<boolean>>;
}

const Header: React.FC<HeaderProps> = (props) => {
  const { setOpenDrawer } = props;

  return (
    <HeaderWrapper>
      <ContentContainer>
        <Logo />
        <MenuContainer>
          <MenuWrapper>
            {MenuList.map((menu) => (
              <HeaderMenuItem
                key={menu.name}
                inHeader={true}
                name={menu.name}
                href={menu.href}
                target={menu.target}
                color={"#ffffff"}
              />
            ))}
          </MenuWrapper>
          <LangMenu color={"#ffffff"} ItemWrapper={HeaderLangItem} />
        </MenuContainer>
        <HamburgerButton onClick={() => setOpenDrawer(true)}>
          <img src={Hamburger} alt="hamburger-button" />
        </HamburgerButton>
      </ContentContainer>
    </HeaderWrapper>
  );
};

export default Header;
