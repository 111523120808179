import media from "@style/media";
import fonts from "@style/fonts";


const color = {
  deepBlue: "#151926",
  gray: "#2A334C33",
  gray500: "#8B8EA2",
  gray300: "#C7CADB",
  gray200: "#E5E7F2",
  gray100: "#F3F3FA",
  sub1: "#5E6579",
  sub2: "#8D93A6",
  sub3: "#BBC1D2",
  orange: "#FF4E00",
  yellow1: "#FF4E00",
  yellow2: "#FFDE3D"
};

export const theme = {
  color,
  media,
  fonts
};

export type Theme = typeof theme;