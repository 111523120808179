/**
 * @generated SignedSource<<819db7ee556809819501b3d6582b7bcf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ENUM_DISCLOSURE_TYPE = "occasional" | "regularly" | "%future added value";
export type getDisclosureQuery$variables = {
  id: string;
};
export type getDisclosureQuery$data = {
  readonly disclosure: {
    readonly __typename: "DisclosureEntityResponse";
    readonly data: {
      readonly __typename: "DisclosureEntity";
      readonly attributes: {
        readonly __typename: "Disclosure";
        readonly content: string;
        readonly files: {
          readonly __typename: "UploadFileRelationResponseCollection";
          readonly data: ReadonlyArray<{
            readonly " $fragmentSpreads": FragmentRefs<"UploadFileEntity">;
          }>;
        };
        readonly published_date: any;
        readonly title: string;
        readonly type: ENUM_DISCLOSURE_TYPE;
      };
      readonly id: string;
    };
  };
} | null;
export type getDisclosureQuery = {
  response: getDisclosureQuery$data;
  variables: getDisclosureQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "published_date",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getDisclosureQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "DisclosureEntityResponse",
          "kind": "LinkedField",
          "name": "disclosure",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": (v2/*: any*/),
              "action": "LOG",
              "path": "disclosure.__typename"
            },
            {
              "kind": "RequiredField",
              "field": {
                "alias": null,
                "args": null,
                "concreteType": "DisclosureEntity",
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v2/*: any*/),
                    "action": "LOG",
                    "path": "disclosure.data.__typename"
                  },
                  {
                    "kind": "RequiredField",
                    "field": (v3/*: any*/),
                    "action": "LOG",
                    "path": "disclosure.data.id"
                  },
                  {
                    "kind": "RequiredField",
                    "field": {
                      "alias": null,
                      "args": null,
                      "concreteType": "Disclosure",
                      "kind": "LinkedField",
                      "name": "attributes",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": (v2/*: any*/),
                          "action": "LOG",
                          "path": "disclosure.data.attributes.__typename"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v4/*: any*/),
                          "action": "LOG",
                          "path": "disclosure.data.attributes.title"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v5/*: any*/),
                          "action": "LOG",
                          "path": "disclosure.data.attributes.type"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v6/*: any*/),
                          "action": "LOG",
                          "path": "disclosure.data.attributes.content"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v7/*: any*/),
                          "action": "LOG",
                          "path": "disclosure.data.attributes.published_date"
                        },
                        {
                          "kind": "RequiredField",
                          "field": {
                            "alias": null,
                            "args": null,
                            "concreteType": "UploadFileRelationResponseCollection",
                            "kind": "LinkedField",
                            "name": "files",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": (v2/*: any*/),
                                "action": "LOG",
                                "path": "disclosure.data.attributes.files.__typename"
                              },
                              {
                                "kind": "RequiredField",
                                "field": {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "UploadFileEntity",
                                  "kind": "LinkedField",
                                  "name": "data",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "args": null,
                                      "kind": "FragmentSpread",
                                      "name": "UploadFileEntity"
                                    }
                                  ],
                                  "storageKey": null
                                },
                                "action": "LOG",
                                "path": "disclosure.data.attributes.files.data"
                              }
                            ],
                            "storageKey": null
                          },
                          "action": "LOG",
                          "path": "disclosure.data.attributes.files"
                        }
                      ],
                      "storageKey": null
                    },
                    "action": "LOG",
                    "path": "disclosure.data.attributes"
                  }
                ],
                "storageKey": null
              },
              "action": "LOG",
              "path": "disclosure.data"
            }
          ],
          "storageKey": null
        },
        "action": "LOG",
        "path": "disclosure"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getDisclosureQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DisclosureEntityResponse",
        "kind": "LinkedField",
        "name": "disclosure",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DisclosureEntity",
            "kind": "LinkedField",
            "name": "data",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Disclosure",
                "kind": "LinkedField",
                "name": "attributes",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UploadFileRelationResponseCollection",
                    "kind": "LinkedField",
                    "name": "files",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UploadFileEntity",
                        "kind": "LinkedField",
                        "name": "data",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UploadFile",
                            "kind": "LinkedField",
                            "name": "attributes",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "alternativeText",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "caption",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "width",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "height",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "formats",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hash",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "ext",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "mime",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "size",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "previewUrl",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "provider",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "provider_metadata",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "createdAt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "updatedAt",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "aa1e2f60d257839b48adb92226201ff8",
    "id": null,
    "metadata": {},
    "name": "getDisclosureQuery",
    "operationKind": "query",
    "text": "query getDisclosureQuery(\n  $id: ID!\n) {\n  disclosure(id: $id) {\n    __typename\n    data {\n      __typename\n      id\n      attributes {\n        __typename\n        title\n        type\n        content\n        published_date\n        files {\n          __typename\n          data {\n            ...UploadFileEntity\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment UploadFileEntity on UploadFileEntity {\n  __typename\n  id\n  attributes {\n    __typename\n    name\n    alternativeText\n    caption\n    width\n    height\n    formats\n    hash\n    ext\n    mime\n    size\n    url\n    previewUrl\n    provider\n    provider_metadata\n    createdAt\n    updatedAt\n  }\n}\n"
  }
};
})();

(node as any).hash = "0c2974d7ba73786bfbbe18aa59253f8a";

export default node;
