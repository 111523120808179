import { ButtonWrapper, ContentContainer } from "@components/common/etc";
import Subtitle from "@components/common/subtitle";
import { theme } from "@style/theme";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowRightIcon } from "@assets/image/about/inquiries/arrow_right.svg";
import styled from "styled-components";

const MainSectionWrapper = styled.section`
  background-color: ${theme.color.deepBlue};
  color: white;
`;

const JobsTitle = styled.p`
  margin-bottom: 80px;
  ${({ theme }) => theme.fonts.subtitle1}

  ${({ theme }) => theme.media.mobile`
    ${theme.fonts.subtitle3}
  `}
`;

const JobsBody = styled.p`
  margin-bottom: 80px;
  ${({ theme }) => theme.fonts.subtitle1}

  ${({ theme }) => theme.media.tabletS`
    ${theme.fonts.subtitle2}
  `}

  ${({ theme }) => theme.media.mobile`
    ${theme.fonts.subtitle3}
  `}
`;

const JobsButton = styled(ButtonWrapper)`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 38px 40px;
  border: 2px solid #ffffff;
  box-sizing: border-box;

  &:hover,
  &:focus ${ButtonWrapper} {
    background-color: #ffffff33;
  }
`;

const JobsSection = () => {
  const { t } = useTranslation("culture");
  const jobPage = "https://quantit.atlassian.net/wiki/spaces/PTA/overview"
  const openJobPage = () => {
    window.open(jobPage, "_blank");
  };
  return (
    <MainSectionWrapper>
      <ContentContainer padding={{ tabletSmall: 160, mobile: 160 }}>
        <JobsTitle>{t("jobs.label")}</JobsTitle>
        <JobsBody>{t("jobs.description")}</JobsBody>
        <JobsButton onClick={openJobPage}>
          <Subtitle.Two>{t("jobs.button")}</Subtitle.Two>
          <ArrowRightIcon stroke="#FFFFFF" />
        </JobsButton>
      </ContentContainer>
    </MainSectionWrapper>
  );
};

export default JobsSection;
