import { ImageSetProps } from "@components/common/etc";
import Desktop from "./desktop.png";
import Laptop from "./laptop.png";
import TabletLarge from "./tabletLarge.png";
import TabletSmall from "./tabletSmall.png";
import Mobile from "./mobile.png";

const ProductTechImage: ImageSetProps = {
    desktop: { src: Desktop, height: 305, },
    laptop: { src: Laptop, height: 240, },
    tabletL: { src: TabletLarge, height: 232, },
    tabletS: { src: TabletSmall, height: 145, },
    mobile: { src: Mobile, height: 1787, },
    alt: "product-tech-image"
}

export default ProductTechImage
