import { ContentContainer } from "@components/common/etc";
import SuspensePage from "@components/common/suspense";
import DisclosureDetailMain, {
  DisclosureDetailProps,
} from "@components/disclosure/detail";
import { FirstSection } from "@components/layout/banner";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { Link, useOutletContext } from "react-router-dom";
import styled from "styled-components";

const DisclosureSection = styled(FirstSection)`
  min-height: calc(100vh - 72px);
`;

const Content = styled(ContentContainer)`
  min-height: calc(100vh - 72px);
  padding: 0px 0px 0px !important;
  display: flex;
  flex-direction: column;
`;

const GoListButtonWrapper = styled(Link)`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;

  ${({ theme }) => theme.media.tabletL`
    margin-bottom: 40px;
  `}
  ${({ theme }) => theme.media.tabletS`
    margin-bottom: 40px;
  `}
  ${({ theme }) => theme.media.mobile`
    margin-bottom: 40px;
  `}
`;
const GoListButton = styled.button`
  ${({ theme }) => theme.fonts.body5}
  color: ${({ theme }) => theme.color.gray500};
  background-color: ${({ theme }) => theme.color.gray100};
  border-radius: 4px;
  padding: 8px 40px;
  text-align: center;

  ${({ theme }) => theme.media.tabletL`
  padding: 8px 30px;
  `}
  ${({ theme }) => theme.media.tabletS`
    padding: 8px 30px;
  `}
  ${({ theme }) => theme.media.mobile`
    flex: 1;
    padding: 10px 80px;
  `}

  &:hover {
    background-color: ${({ theme }) => theme.color.gray200};
  }
`;

const DisclosureDetail: React.FC = () => {
  const { disclosureId } = useOutletContext<DisclosureDetailProps>();
  const { t } = useTranslation("disclosure");
  return (
    <DisclosureSection>
      <Content>
        <Suspense fallback={<SuspensePage />}>
          <DisclosureDetailMain disclosureId={disclosureId} />
        </Suspense>
        <GoListButtonWrapper to="/disclosure">
          <GoListButton>{t("detail.back")}</GoListButton>
        </GoListButtonWrapper>
      </Content>
    </DisclosureSection>
  );
};

export default DisclosureDetail;
