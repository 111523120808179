/**
 * @generated SignedSource<<6ab213fe759def21fe99eae14f919f08>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UploadFileEntity$data = {
  readonly __typename: "UploadFileEntity";
  readonly attributes: {
    readonly __typename: "UploadFile";
    readonly alternativeText: string | null;
    readonly caption: string | null;
    readonly createdAt: any | null;
    readonly ext: string | null;
    readonly formats: any | null;
    readonly hash: string;
    readonly height: number | null;
    readonly mime: string;
    readonly name: string;
    readonly previewUrl: string | null;
    readonly provider: string;
    readonly provider_metadata: any | null;
    readonly size: number;
    readonly updatedAt: any | null;
    readonly url: string;
    readonly width: number | null;
  };
  readonly id: string;
  readonly " $fragmentType": "UploadFileEntity";
} | null;
export type UploadFileEntity$key = {
  readonly " $data"?: UploadFileEntity$data;
  readonly " $fragmentSpreads": FragmentRefs<"UploadFileEntity">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UploadFileEntity",
  "selections": [
    {
      "kind": "RequiredField",
      "field": (v0/*: any*/),
      "action": "LOG",
      "path": "__typename"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      "action": "LOG",
      "path": "id"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "UploadFile",
        "kind": "LinkedField",
        "name": "attributes",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": (v0/*: any*/),
            "action": "LOG",
            "path": "attributes.__typename"
          },
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            "action": "LOG",
            "path": "attributes.name"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "alternativeText",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "caption",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "width",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "height",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "formats",
            "storageKey": null
          },
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hash",
              "storageKey": null
            },
            "action": "LOG",
            "path": "attributes.hash"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ext",
            "storageKey": null
          },
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mime",
              "storageKey": null
            },
            "action": "LOG",
            "path": "attributes.mime"
          },
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "size",
              "storageKey": null
            },
            "action": "LOG",
            "path": "attributes.size"
          },
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            },
            "action": "LOG",
            "path": "attributes.url"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "previewUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "provider",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "provider_metadata",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      "action": "LOG",
      "path": "attributes"
    }
  ],
  "type": "UploadFileEntity",
  "abstractKey": null
};
})();

(node as any).hash = "c196be977022c1197b7ee4dc25889171";

export default node;
