/**
 * @generated SignedSource<<bcc7b645886633edc64f9bd71bf7ef9c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ENUM_DISCLOSURE_TYPE = "occasional" | "regularly" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DisclosureEntity$data = {
  readonly __typename: "DisclosureEntity";
  readonly attributes: {
    readonly __typename: "Disclosure";
    readonly content: string;
    readonly files: {
      readonly __typename: "UploadFileRelationResponseCollection";
      readonly data: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"UploadFileEntity">;
      }>;
    };
    readonly published_date: any;
    readonly title: string;
    readonly type: ENUM_DISCLOSURE_TYPE;
  };
  readonly id: string;
  readonly " $fragmentType": "DisclosureEntity";
} | null;
export type DisclosureEntity$key = {
  readonly " $data"?: DisclosureEntity$data;
  readonly " $fragmentSpreads": FragmentRefs<"DisclosureEntity">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DisclosureEntity",
  "selections": [
    {
      "kind": "RequiredField",
      "field": (v0/*: any*/),
      "action": "LOG",
      "path": "__typename"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      "action": "LOG",
      "path": "id"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "Disclosure",
        "kind": "LinkedField",
        "name": "attributes",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": (v0/*: any*/),
            "action": "LOG",
            "path": "attributes.__typename"
          },
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            "action": "LOG",
            "path": "attributes.title"
          },
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            "action": "LOG",
            "path": "attributes.type"
          },
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "content",
              "storageKey": null
            },
            "action": "LOG",
            "path": "attributes.content"
          },
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "published_date",
              "storageKey": null
            },
            "action": "LOG",
            "path": "attributes.published_date"
          },
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": null,
              "concreteType": "UploadFileRelationResponseCollection",
              "kind": "LinkedField",
              "name": "files",
              "plural": false,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": (v0/*: any*/),
                  "action": "LOG",
                  "path": "attributes.files.__typename"
                },
                {
                  "kind": "RequiredField",
                  "field": {
                    "alias": null,
                    "args": null,
                    "concreteType": "UploadFileEntity",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": true,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "UploadFileEntity"
                      }
                    ],
                    "storageKey": null
                  },
                  "action": "LOG",
                  "path": "attributes.files.data"
                }
              ],
              "storageKey": null
            },
            "action": "LOG",
            "path": "attributes.files"
          }
        ],
        "storageKey": null
      },
      "action": "LOG",
      "path": "attributes"
    }
  ],
  "type": "DisclosureEntity",
  "abstractKey": null
};
})();

(node as any).hash = "7216bc684b1e2ba668b665aa8e226d9b";

export default node;
