import { ImageSetProps } from "@components/common/etc";
import Large from "./large.svg";
import Medium from "./medium.svg";
import Small from "./small.svg";

const PortfolioThreeImage: ImageSetProps = {
    desktop: { src: Large, height: 360, width: 360, },
    laptop: { src: Large, height: 360, width: 360, },
    tabletL: { src: Medium, height: 240, width: 360, },
    tabletS: { src: Small, height: 184, width: 184, },
    mobile: { src: Small, height: 184, width: 184, },
    alt: "portfolio-three-image"
}

export default PortfolioThreeImage