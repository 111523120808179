import { CheckMediaQuery } from "@/utils";
import BodyText from "@components/common/bodyText";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const MainBodyContent = styled(BodyText.Three)`
  color: white;
  text-align: center;
`;

const MobileBodyContent = styled(BodyText.Four)`
  color: white;
  text-align: center;
`;

const BodyContent: React.FC = () => {
  const mediaQuery = CheckMediaQuery();

  const { t } = useTranslation("about");

  return (
    <>
      {!mediaQuery.isMobile && (
        <MainBodyContent>
          {t("main.body1")}
          <br />
          {t("main.body2")}
        </MainBodyContent>
      )}
      {mediaQuery.isMobile && (
        <MobileBodyContent>{`${t("main.body1")} ${t(
          "main.body2"
        )}`}</MobileBodyContent>
      )}
    </>
  );
};

export default BodyContent;
