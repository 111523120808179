import EsgImage from "@assets/image/product/finter/data/esg";
import EwsImage from "@assets/image/product/finter/data/ews";
import QuandaImage from "@assets/image/product/finter/data/quanda";
import ThemeImage from "@assets/image/product/finter/data/theme";
import PortfolioOneImage from "@assets/image/product/finter/portfolio/value1";
import PortfolioTwoImage from "@assets/image/product/finter/portfolio/value2";
import PortfolioThreeImage from "@assets/image/product/finter/portfolio/value3";
import PortfolioFourImage from "@assets/image/product/finter/portfolio/value4";
import TradingOneImage from "@assets/image/product/finter/trading/value1";
import TradingTwoImage from "@assets/image/product/finter/trading/value2";
import TradingThreeImage from "@assets/image/product/finter/trading/value3";
import { ImageSetProps } from "@components/common/etc";

export interface FinterInfoItemProps {
  key: string;
  image: ImageSetProps;
}

export interface FinterInfoDataProps {
  key: string;
  items: Array<FinterInfoItemProps>;
}

const FinterInfoData: Array<FinterInfoDataProps> = [
  {
    key: "finter.data",
    items: [
      { key: "finter.data.quanda", image: QuandaImage },
      { key: "finter.data.ews", image: EwsImage },
      { key: "finter.data.esg", image: EsgImage },
      { key: "finter.data.theme", image: ThemeImage },
    ],
  },
  {
    key: "finter.portfolio",
    items: [
      { key: "finter.portfolio.value1", image: PortfolioOneImage },
      { key: "finter.portfolio.value2", image: PortfolioTwoImage },
      { key: "finter.portfolio.value3", image: PortfolioThreeImage },
      { key: "finter.portfolio.value4", image: PortfolioFourImage },
    ],
  },
  {
    key: "finter.trading",
    items: [
      { key: "finter.trading.value1", image: TradingOneImage },
      { key: "finter.trading.value2", image: TradingTwoImage },
      { key: "finter.trading.value3", image: TradingThreeImage }
    ],
  },
];

export default FinterInfoData;
