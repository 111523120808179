import HistoryJson from "@assets/data/about/history.json";

export interface HistoryItemProps {
  month: string;
  descriptionKey: string;
}

export interface HistoryYearProps {
  year: string;
  items: Array<HistoryItemProps>
}


const HistoryData: Array<HistoryYearProps> = HistoryJson;

export default HistoryData;
