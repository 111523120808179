import { ImageSetProps } from "@components/common/etc";
import Desktop from "./desktop.png";
import Laptop from "./laptop.png";
import TabletLarge from "./tabletLarge.png";
import TabletSmall from "./tabletSmall.png";
import Mobile from "./mobile.png";

const EnglishMainImage: ImageSetProps = {
    desktop: { src: Desktop, height: 202, width: 1128 },
    laptop: { src: Laptop, height: 157, width: 880 },
    tabletL: { src: TabletLarge, height: 154, width: 861 },
    tabletS: { src: TabletSmall, height: 96, width: 536 },
    mobile: { src: Mobile, height: 59, width: 324 },
    alt: "english-main-image"
}

export default EnglishMainImage
