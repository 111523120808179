import { FlexContainer, ImageContainer } from "@components/common/etc";
import FinterInfoData, {
  FinterInfoDataProps,
  FinterInfoItemProps,
} from "@components/product/finter/const";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface InfoItemContainerProps {
  item: FinterInfoItemProps;
}

const InfoItemContainerWrapper = styled(FlexContainer)`
  flex-direction: row;

  ${({ theme }) => theme.media.laptop`
    margin-bottom: 24px;
  `}

  ${({ theme }) => theme.media.tabletL`
    margin-bottom: 16px;
  `}

  ${({ theme }) => theme.media.tabletS`
    margin-bottom: 64px;
  `}

  ${({ theme }) => theme.media.mobile`
    flex-direction: column;
    margin-bottom: 64px;
  `}

  &:last-child{
    margin-bottom: 0px !important;
  }
`;

const InfoItemImageContainer = styled(FlexContainer)`
  margin-right: 24px;

  ${({ theme }) => theme.media.mobile`
    justify-content: center;
    margin-right: 0px;
    margin-bottom: 24px;
  `}
`;

const InfoItemTextWrapper = styled(FlexContainer)`
  flex-direction: column;
  justify-content: center;
`;

const InfoItemTitle = styled.p`
  ${({ theme }) => theme.fonts.subtitle1}
  margin-bottom: 24px;

  ${({ theme }) => theme.media.tabletL`
    ${theme.fonts.subtitle2}
  `}
  ${({ theme }) => theme.media.tabletS`
    ${theme.fonts.subtitle3}
    margin-bottom: 8px;
  `}
  ${({ theme }) => theme.media.mobile`
    ${theme.fonts.subtitle3}
    margin-bottom: 8px;
  `}
`;

const InfoItemDescription = styled.p`
  ${({ theme }) => theme.fonts.subtitle3}
  margin-bottom: 24px;

  ${({ theme }) => theme.media.tabletL`
    ${theme.fonts.body3}
  `}
  ${({ theme }) => theme.media.tabletS`
    ${theme.fonts.body3}
  `}
  ${({ theme }) => theme.media.mobile`
    ${theme.fonts.body3}
  `}
`;

const InfoItemContainer: React.FC<InfoItemContainerProps> = (props) => {
  const { key, image } = props.item;
  const { t } = useTranslation("product");
  return (
    <InfoItemContainerWrapper>
      <InfoItemImageContainer>
        <ImageContainer image={image} />
      </InfoItemImageContainer>
      <InfoItemTextWrapper>
        <InfoItemTitle>{t(`${key}.title`)}</InfoItemTitle>
        <InfoItemDescription>{t(`${key}.description`)}</InfoItemDescription>
      </InfoItemTextWrapper>
    </InfoItemContainerWrapper>
  );
};

const FinterInfoWrapper = styled.div`
  margin-top: 80px;
  padding-bottom: 80px;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray300};

  &:first-child {
    margin-top: 240px;

    ${({ theme }) => theme.media.tabletS`
        margin-top : 160px;
    `}

    ${({ theme }) => theme.media.mobile`
        margin-top : 160px;
    `}
  }

  &:last-child {
    border-bottom: none;
  }
`;

interface FinterInfoContainerProps {
  info: FinterInfoDataProps;
}

const FinterInfoLabel = styled.p`
  margin-bottom: 24px;
  ${({ theme }) => theme.fonts.h5}
  ${({ theme }) => theme.media.tabletL`
    ${theme.fonts.subtitle1}
  `}
  ${({ theme }) => theme.media.tabletS`
    ${theme.fonts.subtitle1}
    margin-bottom: 40px;
  `}
  ${({ theme }) => theme.media.mobile`
    ${theme.fonts.subtitle1}
    margin-bottom: 40px;
  `}
`;

const FinterInfoContainer: React.FC<FinterInfoContainerProps> = (props) => {
  const { key, items } = props.info;
  const { t } = useTranslation("product");
  return (
    <FinterInfoWrapper>
      <FinterInfoLabel>{t(`${key}.label`)}</FinterInfoLabel>
      {items.map((item) => (
        <InfoItemContainer key={item.key} item={item} />
      ))}
    </FinterInfoWrapper>
  );
};

const FinterInfoPanel = () => {
  return (
    <div>
      {FinterInfoData.map((info) => (
        <FinterInfoContainer key={info.key} info={info} />
      ))}
    </div>
  );
};

export default FinterInfoPanel;
