import Drawer from "@components/layout/drawer";
import Footer from "@components/layout/footer";
import Header from "@components/layout/header";
import HelmetContainer from "@components/layout/helmet";
import { useState } from "react";
import styled from "styled-components";

const Main = styled.main`
  min-height: 100vh;
  width: 100%;
`;
interface LayoutProps {
  title: string;
  children?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = (props) => {
  const { title, children } = props;

  const [openDrawer, setOpenDrawer] = useState(false);

  const _title = () => {
    if (title === "Home") {
      return "Quantit | 퀀팃 | AI Fintech Company";
    } else {
      return `Quantit | 퀀팃 | AI Fintech Company - ${title}`;
    }
  };
  return (
    <>
      <HelmetContainer title={_title()} />
      <Drawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
      <Header setOpenDrawer={setOpenDrawer} />
      <Main>{children}</Main>
      <Footer />{" "}
    </>
  );
};

export default Layout;
