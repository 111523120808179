import type { getPopupQuery as getPopupQueryType } from "@query/__generated__/getPopupQuery.graphql";
import { GetPopupQuery } from "@query/get";
import dayjs from "dayjs";
import { Suspense, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLazyLoadQuery } from "react-relay";
import styled from "styled-components";

const DialogWrapper = styled.dialog`
  border: none;
  border-radius: 4px;
  padding: 0px;
  min-width: 300px;

  &::backdrop {
    background-color: rgba(0, 0, 0, 0.7);
  }

  &:focus {
    outline: none;
  }
`;

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
`;

const DialogLabel = styled.div`
  margin-bottom: 20px;
  label {
    ${({ theme }) => theme.fonts.caption};
    color: white;
    background-color: ${({ theme }) => theme.color.deepBlue};
    padding: 4px 8px;
    border-radius: 4px;
  }
`;

const DialogTitle = styled.h2`
  ${({ theme }) => theme.fonts.subtitle1};
  margin-bottom: 20px;
`;

const DialogDescription = styled.p`
  ${({ theme }) => theme.fonts.body5};
  color: #6d7078;
  margin-bottom: 20px;
`;

const DialogLinkButton = styled.a`
  width: calc(100% - 40px);
  text-align: center;
  padding: 10px 20px;
  box-sizing: content-box;
  ${({ theme }) => theme.fonts.body4};
  font-weight: 700 !important;
  text-align: center !important;
  background-color: ${({ theme }) => theme.color.gray100};
  border-radius: 4px;

  &:hover {
    background-color: ${({ theme }) => theme.color.gray200};
  }
  &:focus {
    outline: none;
  }
`;

const DialogButtonForm = styled.form`
  padding: 20px;
  border-top: 1px solid ${({ theme }) => theme.color.gray200};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const DialogButton = styled.button`
  display: flex;
  padding: 4px 8px;
  border-radius: 4px;
  &:hover {
    background-color: ${({ theme }) => theme.color.gray100};
  }
  span {
    ${({ theme }) => theme.fonts.caption};
    font-weight: 400 !important;
    color: ${({ theme }) => theme.color.gray300};
    line-height: 1 !important;
  }
`;

const Dialog: React.FC = () => {
  const _popupCloseTime = localStorage.getItem("popupCloseTime");
  const showPopup =
    _popupCloseTime == null ||
    dayjs(_popupCloseTime).format("YYYY-MM-DD") !==
      dayjs().format("YYYY-MM-DD");

  const data = useLazyLoadQuery<getPopupQueryType>(GetPopupQuery, {});
  const { t } = useTranslation("dialog");

  useEffect(() => {
    const dialog = document.getElementById("dialog") as HTMLDialogElement;
    if (dialog) {
      const close = () => {
        if (dialog.returnValue === "no-today") {
          localStorage.setItem("popupCloseTime", dayjs().format("YYYY-MM-DD"));
        }
        document.body.style.overflow = "auto";
      };

      dialog.addEventListener("close", close);

      return () => {
        dialog.removeEventListener("close", close);
      };
    }
  }, []);

  useEffect(() => {
    if (data?.popup.data) {
      const dialog = document.getElementById("dialog") as HTMLDialogElement;
      if (dialog && !dialog.open && showPopup) {
        dialog.showModal();
        document.body.style.overflow = "hidden";
      }
    }
  }, [data, showPopup]);

  return (
    data &&
    data.popup.data && (
      <DialogWrapper id="dialog">
        <DialogContent>
          <DialogLabel>
            <label>{t("label")}</label>
          </DialogLabel>
          <DialogTitle>{data.popup.data.attributes.title}</DialogTitle>
          <DialogDescription>
            {data.popup.data.attributes.description
              .split("\n")
              .map((line, idx) =>
                idx === 0 ? (
                  <span key={`dialog-description-${idx}`}>{line}</span>
                ) : (
                  <span key={`dialog-description-${idx}`}>
                    <br />
                    {line}
                  </span>
                )
              )}
          </DialogDescription>
          {data.popup.data.attributes.link && (
            <DialogLinkButton href={data.popup.data.attributes.link}>
              {t("view")}
            </DialogLinkButton>
          )}
        </DialogContent>
        <DialogButtonForm method="dialog">
          <DialogButton key="no-today" value="no-today">
            <span>{t("noToday")}</span>
          </DialogButton>
          <DialogButton key="close" value="close">
            <span>{t("close")}</span>
          </DialogButton>
        </DialogButtonForm>
      </DialogWrapper>
    )
  );
};

const DialogContainer: React.FC = () => {
  return (
    <>
      <Suspense fallback={<></>}>
        <Dialog />
      </Suspense>
    </>
  );
};
export default DialogContainer;
