import graphql from "babel-plugin-relay/macro";


export const GetDisclosuresQuery = graphql`
  query getDisclosuresQuery(
    $search: String, 
    $page: Int = 1
    $pageSize: Int = 10
  ){
    disclosures(
      filters:{
        title: { contains: $search}
      }
      pagination: {page: $page, pageSize: $pageSize}
      sort: ["createdAt:desc"]
    ) @required(action: LOG) {
      __typename @required(action: LOG)
      data @required(action: LOG) {
        ...DisclosureEntity 
      }
      meta @required(action: LOG) {
        ...ResponseCollectionMeta @relay(mask: false)
      }
    }
  }  
`;

export const GetDisclosureQuery = graphql`
  query getDisclosureQuery($id: ID!){
    disclosure(id: $id) @required(action: LOG) {
      __typename @required(action: LOG)
      data @required(action: LOG) {
        ...DisclosureEntity @relay(mask: false)
      }
    }
  }  
`;


export const GetPopupQuery = graphql`
  query getPopupQuery{
    popup @required(action: LOG) {
      ...PopupEntityResponse @relay(mask: false)
    }
  }
`;
