import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollTo() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash.length > 0) {
      const element = document.getElementById(location.hash.replace("#", ""));

      element?.scrollIntoView({
        block: "start",
      });
    } else {
      window.scrollTo({
        top: 0,
      });
    }
  }, [location]);

  return <></>;
}
