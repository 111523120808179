/**
 * @generated SignedSource<<982d28dbcce738c46f3be198cff5e347>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UploadFile$data = {
  readonly __typename: "UploadFile";
  readonly alternativeText: string | null;
  readonly caption: string | null;
  readonly createdAt: any | null;
  readonly ext: string | null;
  readonly formats: any | null;
  readonly hash: string;
  readonly height: number | null;
  readonly mime: string;
  readonly name: string;
  readonly previewUrl: string | null;
  readonly provider: string;
  readonly provider_metadata: any | null;
  readonly size: number;
  readonly updatedAt: any | null;
  readonly url: string;
  readonly width: number | null;
  readonly " $fragmentType": "UploadFile";
} | null;
export type UploadFile$key = {
  readonly " $data"?: UploadFile$data;
  readonly " $fragmentSpreads": FragmentRefs<"UploadFile">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UploadFile",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      "action": "LOG",
      "path": "__typename"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      "action": "LOG",
      "path": "name"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "alternativeText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "caption",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "width",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "height",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "formats",
      "storageKey": null
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hash",
        "storageKey": null
      },
      "action": "LOG",
      "path": "hash"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ext",
      "storageKey": null
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mime",
        "storageKey": null
      },
      "action": "LOG",
      "path": "mime"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "size",
        "storageKey": null
      },
      "action": "LOG",
      "path": "size"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      },
      "action": "LOG",
      "path": "url"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "previewUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "provider",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "provider_metadata",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    }
  ],
  "type": "UploadFile",
  "abstractKey": null
};

(node as any).hash = "b8f071d3ddbf816c8d8e01741dcfdba6";

export default node;
