import SectionLabel from "@components/common/label";
import {
  ContentContainer,
  LanguageSwitcher,
  ButtonWrapper,
} from "@components/common/etc";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Link } from "react-router-dom";

const CultureSectionWrapper = styled.section`
  background-color: ${({ theme }) => theme.color.gray100};
`;

const BodyText = styled.p`
  ${({ theme }) => theme.fonts.subtitle1}

  ${({ theme }) => theme.media.tabletS`
    ${theme.fonts.subtitle2}
  `}
  ${({ theme }) => theme.media.mobile`
    ${theme.fonts.subtitle3}
  `}
`;

const KoreanContainer = () => {
  const { t } = useTranslation("about");

  return (
    <>
      <BodyText>{t("culture.body1")}</BodyText>
      <br />
      <BodyText>{t("culture.body2")}</BodyText>
    </>
  );
};

const EnglishContainer = () => {
  const { t } = useTranslation("about");

  return <BodyText>{t("culture.body")}</BodyText>;
};

const ButtonContainer = styled(Link)`
  margin-top: 80px;
  display: flex;
  justify-content: center;
  color: inherit;
`;

const ViewButton = styled(ButtonWrapper)`
  padding: 16px 56px;
  border: 2px solid ${({ theme }) => theme.color.deepBlue};

  &:hover,
  &:focus ${ButtonWrapper} {
    background-color: ${({ theme }) => theme.color.deepBlue}33;
  }
`;

const CultureSection = () => {
  const { t } = useTranslation("about");

  const goCulturePage = () => {
    // TODO  > 기업 문화 페이지로 앵커
    console.log("// TODO  > 기업 문화 페이지로 앵커");
  };
  return (
    <CultureSectionWrapper>
      <ContentContainer>
        <SectionLabel languageFileName="about" labelKey="culture.label" />
        <LanguageSwitcher
          target="about"
          KoreanContainer={KoreanContainer}
          EnglishContainer={EnglishContainer}
        />
        <ButtonContainer to="/culture">
          <ViewButton onClick={goCulturePage}>{t("culture.button")}</ViewButton>
        </ButtonContainer>
      </ContentContainer>
    </CultureSectionWrapper>
  );
};

export default CultureSection;
