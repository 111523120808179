export interface MapData {
  addressKey: string;
  url: string;
}

export const OfficeMapArray: Array<MapData> = [
  {
    addressKey: "inquiries.address.kt",
    url: "https://map.naver.com/p/search/%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%20%EC%98%81%EB%93%B1%ED%8F%AC%EA%B5%AC%20%EC%97%AC%EC%9D%98%EB%8C%80%EB%A1%9C%2014%2C%20KT%20%20%EC%97%AC%EC%9D%98%EB%8F%84%ED%83%80%EC%9B%8C%2013%20%20%EC%B8%B5/address/14128573.6582339,4512069.0811148,%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%20%EC%98%81%EB%93%B1%ED%8F%AC%EA%B5%AC%20%EC%97%AC%EC%9D%98%EB%8C%80%EB%A1%9C%2014,new?c=19.00,0,0,0,dh&isCorrectAnswer=true",
  },
  {
    addressKey: "inquiries.address.pangyo",
    url: "https://map.naver.com/p/search/%EA%B2%BD%EA%B8%B0%EB%8F%84%20%EC%84%B1%EB%82%A8%EC%8B%9C%20%EB%B6%84%EB%8B%B9%EA%B5%AC%20%ED%8C%90%EA%B5%90%EC%97%AD%EB%A1%9C%20180%2C%20%EC%95%8C%ED%8C%8C%ED%83%80%EC%9B%8C%20402%ED%98%B8/address/14149827.4544651,4494550.5185936,%EA%B2%BD%EA%B8%B0%EB%8F%84%20%EC%84%B1%EB%82%A8%EC%8B%9C%20%EB%B6%84%EB%8B%B9%EA%B5%AC%20%ED%8C%90%EA%B5%90%EC%97%AD%EB%A1%9C%20180,new?c=18.93,0,0,0,dh&isCorrectAnswer=true"
  }
];