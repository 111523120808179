import graphql from "babel-plugin-relay/macro";

export const UploadFileRelationResponseCollection = graphql`
  fragment UploadFileRelationResponseCollection on UploadFileRelationResponseCollection{
    __typename @required(action: LOG)
    data @required(action: LOG){
      ...UploadFileEntity
    }
  }
`;

export const UploadFileEntity = graphql`
  fragment UploadFileEntity on UploadFileEntity{
    __typename @required(action: LOG)
    id @required(action: LOG)
    attributes @required(action: LOG){
      ...UploadFile @relay(mask: false)
    }
  }
`;

export const UploadFile = graphql`
  fragment UploadFile on UploadFile{
    __typename @required(action: LOG)
    name @required(action: LOG)
    alternativeText
    caption
    width
    height
    formats
    hash @required(action: LOG)
    ext
    mime @required(action: LOG)
    size @required(action: LOG)
    url @required(action: LOG)
    previewUrl
    provider
    provider_metadata
    createdAt
    updatedAt
  }
`;
