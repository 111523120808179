import { ImageSetProps } from "@components/common/etc";
import Large from "./Large.png";
import Medium from "./Medium.svg";
import Small from "./Small.svg";

const QtadvisorLogo: ImageSetProps = {
    desktop: { src: Large, height: 96, width: 264, },
    laptop: { src: Medium, height: 96, width: 202, },
    tabletL: { src: Small, height: 96, width: 196, },
    tabletS: { src: Small, height: 96, width: 196, },
    mobile: { src: Small, height: 96, width: 196, },
    alt: "qtadvisor-logo"
}

export default QtadvisorLogo
