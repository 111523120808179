/**
 * @generated SignedSource<<0b96044e6c4b1daca9947f8b98c26f99>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type getDisclosuresQuery$variables = {
  page?: number | null;
  pageSize?: number | null;
  search?: string | null;
};
export type getDisclosuresQuery$data = {
  readonly disclosures: {
    readonly __typename: "DisclosureEntityResponseCollection";
    readonly data: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"DisclosureEntity">;
    }>;
    readonly meta: {
      readonly __typename: "ResponseCollectionMeta";
      readonly pagination: {
        readonly __typename: "Pagination";
        readonly page: number;
        readonly pageCount: number;
        readonly pageSize: number;
        readonly total: number;
      };
    };
  };
} | null;
export type getDisclosuresQuery = {
  response: getDisclosuresQuery$data;
  variables: getDisclosuresQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": 1,
  "kind": "LocalArgument",
  "name": "page"
},
v1 = {
  "defaultValue": 10,
  "kind": "LocalArgument",
  "name": "pageSize"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v3 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "contains",
            "variableName": "search"
          }
        ],
        "kind": "ObjectValue",
        "name": "title"
      }
    ],
    "kind": "ObjectValue",
    "name": "filters"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "page",
        "variableName": "page"
      },
      {
        "kind": "Variable",
        "name": "pageSize",
        "variableName": "pageSize"
      }
    ],
    "kind": "ObjectValue",
    "name": "pagination"
  },
  {
    "kind": "Literal",
    "name": "sort",
    "value": [
      "createdAt:desc"
    ]
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "page",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pageCount",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pageSize",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "getDisclosuresQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v3/*: any*/),
          "concreteType": "DisclosureEntityResponseCollection",
          "kind": "LinkedField",
          "name": "disclosures",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": (v4/*: any*/),
              "action": "LOG",
              "path": "disclosures.__typename"
            },
            {
              "kind": "RequiredField",
              "field": {
                "alias": null,
                "args": null,
                "concreteType": "DisclosureEntity",
                "kind": "LinkedField",
                "name": "data",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "DisclosureEntity"
                  }
                ],
                "storageKey": null
              },
              "action": "LOG",
              "path": "disclosures.data"
            },
            {
              "kind": "RequiredField",
              "field": {
                "alias": null,
                "args": null,
                "concreteType": "ResponseCollectionMeta",
                "kind": "LinkedField",
                "name": "meta",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v4/*: any*/),
                    "action": "LOG",
                    "path": "disclosures.meta.__typename"
                  },
                  {
                    "kind": "RequiredField",
                    "field": {
                      "alias": null,
                      "args": null,
                      "concreteType": "Pagination",
                      "kind": "LinkedField",
                      "name": "pagination",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": (v4/*: any*/),
                          "action": "LOG",
                          "path": "disclosures.meta.pagination.__typename"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v5/*: any*/),
                          "action": "LOG",
                          "path": "disclosures.meta.pagination.total"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v6/*: any*/),
                          "action": "LOG",
                          "path": "disclosures.meta.pagination.page"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v7/*: any*/),
                          "action": "LOG",
                          "path": "disclosures.meta.pagination.pageCount"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v8/*: any*/),
                          "action": "LOG",
                          "path": "disclosures.meta.pagination.pageSize"
                        }
                      ],
                      "storageKey": null
                    },
                    "action": "LOG",
                    "path": "disclosures.meta.pagination"
                  }
                ],
                "storageKey": null
              },
              "action": "LOG",
              "path": "disclosures.meta"
            }
          ],
          "storageKey": null
        },
        "action": "LOG",
        "path": "disclosures"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "getDisclosuresQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "DisclosureEntityResponseCollection",
        "kind": "LinkedField",
        "name": "disclosures",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DisclosureEntity",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Disclosure",
                "kind": "LinkedField",
                "name": "attributes",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "content",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "published_date",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UploadFileRelationResponseCollection",
                    "kind": "LinkedField",
                    "name": "files",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UploadFileEntity",
                        "kind": "LinkedField",
                        "name": "data",
                        "plural": true,
                        "selections": [
                          (v4/*: any*/),
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UploadFile",
                            "kind": "LinkedField",
                            "name": "attributes",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "alternativeText",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "caption",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "width",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "height",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "formats",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hash",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "ext",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "mime",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "size",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "previewUrl",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "provider",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "provider_metadata",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "createdAt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "updatedAt",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ResponseCollectionMeta",
            "kind": "LinkedField",
            "name": "meta",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Pagination",
                "kind": "LinkedField",
                "name": "pagination",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f159e7d4f90d9d007a28bcc162c7eb41",
    "id": null,
    "metadata": {},
    "name": "getDisclosuresQuery",
    "operationKind": "query",
    "text": "query getDisclosuresQuery(\n  $search: String\n  $page: Int = 1\n  $pageSize: Int = 10\n) {\n  disclosures(filters: {title: {contains: $search}}, pagination: {page: $page, pageSize: $pageSize}, sort: [\"createdAt:desc\"]) {\n    __typename\n    data {\n      ...DisclosureEntity\n      id\n    }\n    meta {\n      __typename\n      pagination {\n        __typename\n        total\n        page\n        pageCount\n        pageSize\n      }\n    }\n  }\n}\n\nfragment DisclosureEntity on DisclosureEntity {\n  __typename\n  id\n  attributes {\n    __typename\n    title\n    type\n    content\n    published_date\n    files {\n      __typename\n      data {\n        ...UploadFileEntity\n        id\n      }\n    }\n  }\n}\n\nfragment UploadFileEntity on UploadFileEntity {\n  __typename\n  id\n  attributes {\n    __typename\n    name\n    alternativeText\n    caption\n    width\n    height\n    formats\n    hash\n    ext\n    mime\n    size\n    url\n    previewUrl\n    provider\n    provider_metadata\n    createdAt\n    updatedAt\n  }\n}\n"
  }
};
})();

(node as any).hash = "df869c8a56df0ccec2db6c798204dbd5";

export default node;
