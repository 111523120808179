import styled from "styled-components";

const Body1 = styled.p`
  ${({ theme }) => theme.fonts.body1``}
`;

const Body2 = styled.p`
  ${({ theme }) => theme.fonts.body2``}
`;

const Body3 = styled.p`
  ${({ theme }) => theme.fonts.body3``}
`;

const Body4 = styled.p`
  ${({ theme }) => theme.fonts.body4``}
`;

const Body5 = styled.p`
  ${({ theme }) => theme.fonts.body5``}
`;

const Body6 = styled.p`
  ${({ theme }) => theme.fonts.body6``}
`;

const  BodyText = {
  One: Body1,
  Two: Body2,
  Three: Body3,
  Four: Body4,
  Five: Body5,
  Six: Body6,
};

export default BodyText;
