import { useTranslation } from "react-i18next";
import styled from "styled-components";

const SectionTitleWrapper = styled.div`
  margin-bottom: 80px;

  ${({ theme }) => theme.media.laptop`
    margin-bottom: 60px;
  `}

  ${({ theme }) => theme.media.tabletL`
    margin-bottom: 50px;
  `}

  ${({ theme }) => theme.media.tabletS`
    margin-bottom: 40px;
  `}

  ${({ theme }) => theme.media.mobile`
    margin-bottom: 30px;
  `}
`;

const TitleText = styled.h1`
  ${({ theme }) => theme.fonts.subtitle1}
  ${({ theme }) => theme.media.tabletL`
    ${theme.fonts.subtitle2}
  `}

  ${({ theme }) => theme.media.tabletS`
    ${theme.fonts.subtitle2}
  `}

  ${({ theme }) => theme.media.mobile`
    ${theme.fonts.subtitle3}
  `}
`;

const DisclosureTitle: React.FC = () => {
  const { t } = useTranslation("disclosure");

  return (
    <SectionTitleWrapper>
      <TitleText>{t("title")}</TitleText>
    </SectionTitleWrapper>
  );
};
export default DisclosureTitle;
