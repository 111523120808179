import BodyText from "@components/common/bodyText";
import {
  ContentContainer,
  FlexContainer,
  LanguageSwitcher,
} from "@components/common/etc";
import { TitleText } from "@components/culture/common";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const ItemTitleText = styled.p`
  margin-bottom: 16px;
  ${({ theme }) => theme.fonts.subtitle1}

  ${({ theme }) => theme.media.tabletL`
    ${theme.fonts.subtitle2}
  `}
  ${({ theme }) => theme.media.tabletS`
    ${theme.fonts.subtitle2}
  `}
  ${({ theme }) => theme.media.mobile`
    ${theme.fonts.subtitle2}
  `}
`;

const ItemBodyText = styled.p`
  ${({ theme }) => theme.fonts.body3}

  ${({ theme }) => theme.media.tabletL`
    ${theme.fonts.body4}
  `}
  ${({ theme }) => theme.media.tabletS`
    ${theme.fonts.body3}
  `}
  ${({ theme }) => theme.media.mobile`
    ${theme.fonts.body3}
  `}
`;

const ItemBoxWrapper = styled(FlexContainer)`
  flex-direction: column;
  flex: 1;

  &:first-child {
    margin-right: 24px;

    ${({ theme }) => theme.media.tabletL`
      margin-right: 0px;
      margin-bottom: 80px;
    `}
    ${({ theme }) => theme.media.tabletS`
      margin-right: 0px;
      margin-bottom: 80px;
    `}
    ${({ theme }) => theme.media.mobile`
      margin-right: 0px;
      margin-bottom: 80px;
    `}
  }
`;

interface ItemBoxProps {
  itemKey: string;
}

const ItemBox: React.FC<ItemBoxProps> = (props) => {
  const { itemKey } = props;
  const { t } = useTranslation("culture");
  return (
    <ItemBoxWrapper>
      <ItemTitleText>{t(`support.${itemKey}.title`)}</ItemTitleText>
      <ItemBodyText>{t(`support.${itemKey}.description`)}</ItemBodyText>
    </ItemBoxWrapper>
  );
};

interface RowContainerProps {
  idx: number;
}

const RowContainerWrapper = styled(FlexContainer)`
  flex-direction: row;
  margin-bottom: 80px;

  &:last-child {
    margin-bottom: 0px;
  }

  ${({ theme }) => theme.media.tabletL`
    flex-direction: column;
  `}
  ${({ theme }) => theme.media.tabletS`
    flex-direction: column;
  `}
  ${({ theme }) => theme.media.mobile`
    flex-direction: column;
  `}
`;

const RowContainer: React.FC<RowContainerProps> = (props) => {
  const { idx } = props;
  return (
    <RowContainerWrapper>
      <ItemBox itemKey={`row${idx}.item1`}></ItemBox>
      <ItemBox itemKey={`row${idx}.item2`}></ItemBox>
    </RowContainerWrapper>
  );
};

const SubtitleText = styled(BodyText.Three)`
  margin-bottom: 80px;
`;

const SupportSection = () => {
  const rowIndexArray = Array.from({ length: 5 }, (_, i) => i + 1);
  const { t } = useTranslation("culture");

  return (
    <section>
      <ContentContainer padding={{ tabletLarge: 160, mobile: 160 }}>
        <LanguageSwitcher
          target="culture"
          KoreanContainer={() => (
            <TitleText>
              {t("support.title1")}
              <br />
              {t("support.title2")}
            </TitleText>
          )}
          EnglishContainer={() => <TitleText>{t("support.title")}</TitleText>}
        />
        <SubtitleText>{t("support.subtitle")}</SubtitleText>
        {rowIndexArray.map((idx) => (
          <RowContainer key={`row-${idx}`} idx={idx} />
        ))}
      </ContentContainer>
    </section>
  );
};

export default SupportSection;
