import { FlexContainer } from "@components/common/etc";
import Subtitle from "@components/common/subtitle";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ReactComponent as ArrowRightIcon } from "@assets/image/about/inquiries/arrow_right.svg";
import { theme } from "@style/theme";
import { Link } from "react-router-dom";
import { OfficeMapArray } from "@components/about/Inquiries/const";

export interface InquiriesButtonProps {
  fontcolor?: string;
  hovercolor?: string;
}

const BaseButtonText = styled(Subtitle.Two)`
  ${({ theme }) => theme.media.mobile`
    ${theme.fonts.subtitle3}
  `};
`;

const ButtonValueRow = styled(FlexContainer)`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ButtonLabel = styled(BaseButtonText)`
  width: 120px;
`;

const MailButtonLabel = styled(ButtonLabel)`
  margin-right: 40px;
  ${({ theme }) => theme.media.mobile`
    margin-right: 0px;
    margin-bottom: 40px;
  `};
`;

const MailButtonWrapper = styled(Link)<InquiriesButtonProps>`
  box-sizing: border-box;
  padding: 30px 40px;
  width: 100%;
  margin-bottom: 40px;
  color: inherit;
  border: 2px solid
    ${({ theme, fontcolor }) => fontcolor ?? theme.color.deepBlue};

  display: flex;
  flex-direction: row;
  ${({ theme }) => theme.media.mobile`
    flex-direction: column;
  `};

  &:hover,
  &focus {
    background-color: ${({ theme, hovercolor }) =>
      hovercolor ?? theme.color.deepBlue}33;
  }
`;

const ArrowIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin-left: 40px;
`;

export const MailButton: React.FC<InquiriesButtonProps> = (props) => {
  const _mail = "info@quantit.io";
  const { fontcolor, hovercolor } = props;
  const { t } = useTranslation("about");

  const openMail = (e: React.MouseEvent<HTMLElement>) => {
    window.location.href = `mailto:${_mail}`;
    e.preventDefault();
  };
  return (
    <MailButtonWrapper
      to="#"
      fontcolor={fontcolor}
      hovercolor={hovercolor}
      onClick={openMail}
    >
      <MailButtonLabel>{t("inquiries.email.label")}</MailButtonLabel>
      <ButtonValueRow>
        <BaseButtonText>{_mail}</BaseButtonText>
        <ArrowIconWrapper>
          <ArrowRightIcon stroke={fontcolor ?? theme.color.deepBlue} />
        </ArrowIconWrapper>
      </ButtonValueRow>
    </MailButtonWrapper>
  );
};

const AddressPenalWrapper = styled.div<InquiriesButtonProps>`
  box-sizing: border-box;
  padding: 30px 40px;
  width: 100%;
  color: ${({ theme, fontcolor }) => fontcolor ?? theme.color.deepBlue};
  border: 2px solid
    ${({ theme, fontcolor }) => fontcolor ?? theme.color.deepBlue};

  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.media.desktop`
    flex-direction: row;
  `};
`;

const AddressButtonLabel = styled(ButtonLabel)`
  margin-bottom: 40px;
  ${({ theme }) => theme.media.desktop`
    margin-right: 40px;
    margin-bottom: 0px;
  `};
`;

const AddressWrapper = styled(FlexContainer)`
  flex-direction: column;
  flex: 1;
`;

const AddressRow = styled(Link)<InquiriesButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding-bottom: 24px;
  margin-bottom: 24px;
  color: inherit;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray500};

  &:last-child {
    margin-bottom: 0px;
  }

  &:hover,
  &focus {
    background-color: ${({ theme, hovercolor }) =>
      hovercolor ?? theme.color.deepBlue}33;
  }
`;

export const AddressPanel: React.FC<InquiriesButtonProps> = (props) => {
  const { fontcolor, hovercolor } = props;
  const { t } = useTranslation("about");

  const openMap = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <AddressPenalWrapper fontcolor={fontcolor}>
      <AddressButtonLabel>{t("inquiries.address.label")}</AddressButtonLabel>
      <AddressWrapper>
        {OfficeMapArray.map((data) => (
          <AddressRow
            key={data.addressKey}
            to="#"
            hovercolor={hovercolor}
            onClick={() => openMap(data.url)}
          >
            <BaseButtonText>{t(data.addressKey)}</BaseButtonText>
            <ArrowIconWrapper>
              <ArrowRightIcon stroke={fontcolor ?? theme.color.deepBlue} />
            </ArrowIconWrapper>
          </AddressRow>
        ))}
      </AddressWrapper>
    </AddressPenalWrapper>
  );
};
