import styled from "styled-components";
import { mediaSizes } from "@style/media";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { CheckMediaQuery } from "@/utils";

export const FlexContainer = styled.div`
  display: flex;
`;

export const MediaWrapper = styled.div`
  ${({ theme }) => theme.media.desktop`
    width: ${mediaSizes.desktop.mainWidth}px;
    margin: auto;
  `}
  ${({ theme }) => theme.media.laptop`
    width: ${mediaSizes.laptop.mainWidth}px;
    margin: auto;
  `}
  ${({ theme }) => theme.media.tabletL`
    width: ${mediaSizes.tabletL.mainWidth}px;
    margin: auto;
  `}
  ${({ theme }) => theme.media.tabletS`
    width: ${mediaSizes.tabletS.mainWidth}px;
    margin: auto;
  `}
  ${({ theme }) => theme.media.mobile`
    width: ${mediaSizes.mobile.mainWidth}px;
    margin: auto;
  `}
`;

export const ButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CaptionWrapper = styled.caption`
  ${({ theme }) => theme.fonts.caption``}
`;

export interface ImageProps {
  src: string;
  height: number;
  width?: number;
}

export interface ImageSetProps {
  desktop: ImageProps;
  laptop: ImageProps;
  tabletL: ImageProps;
  tabletS: ImageProps;
  mobile: ImageProps;
  alt: string;
}

export interface ImageContainerProps {
  image: ImageSetProps;
}

export const ImageContainer: React.FC<ImageContainerProps> = (props) => {
  const { image } = props;
  const mq = CheckMediaQuery();
  return (
    <>
      {mq.isDesktop && (
        <LazyLoadImage
          wrapperClassName="desktop"
          src={image.desktop.src}
          height={image.desktop.height}
          width={image.desktop.width ?? "auto"}
          alt={image.alt}
          // effect="blur"
        />
      )}
      {mq.isLaptop && (
        <LazyLoadImage
          wrapperClassName="laptop"
          src={image.laptop.src}
          height={image.laptop.height}
          width={image.laptop.width ?? "auto"}
          alt={image.alt}
          // effect="blur"
        />
      )}
      {mq.isTabletLarge && (
        <LazyLoadImage
          wrapperClassName="tablet-large"
          src={image.tabletL.src}
          height={image.tabletL.height}
          width={image.tabletL.width ?? "auto"}
          alt={image.alt}
          effect="blur"
        />
      )}
      {mq.isTabletSmall && (
        <LazyLoadImage
          wrapperClassName="tablet-small"
          src={image.tabletS.src}
          height={image.tabletS.height}
          width={image.tabletS.width ?? "auto"}
          alt={image.alt}
          effect="blur"
        />
      )}
      {mq.isMobile && (
        <LazyLoadImage
          wrapperClassName="mobile"
          src={image.mobile.src}
          height={image.mobile.height}
          width={image.mobile.width ?? "auto"}
          alt={image.alt}
          effect="blur"
          style={{ width: "100%" }}
        />
      )}
    </>
  );
};

interface LanguageSwitcherProps {
  target: string;
  KoreanContainer: React.FC;
  EnglishContainer: React.FC;
}

export const LanguageSwitcher: React.FC<LanguageSwitcherProps> = (props) => {
  const { target, KoreanContainer, EnglishContainer } = props;
  const { i18n } = useTranslation(target);

  if (i18n.language === "ko-KR") {
    return <KoreanContainer />;
  } else {
    return <EnglishContainer />;
  }
};

interface VerticalPaddingProps {
  desktop?: number;
  laptop?: number;
  tabletLarge?: number;
  tabletSmall?: number;
  mobile?: number;
}

interface ContentContainerProps {
  padding?: VerticalPaddingProps;
  color?: string;
}

export const ContentContainer = styled(MediaWrapper)<ContentContainerProps>`
  color: ${(props) => props.color ?? "inherit"};
  padding: ${({ padding }) => padding?.desktop ?? 160}px 0px;
  box-sizing: border-box;

  ${({ theme, padding }) => theme.media.laptop`
    padding: ${padding?.laptop ?? 160}px 0px;
  `}
  ${({ theme, padding }) => theme.media.tabletL`
    padding: ${padding?.tabletLarge ?? 160}px 0px;
  `}

  ${({ theme, padding }) => theme.media.tabletS`
    padding: ${padding?.tabletSmall ?? 96}px 0px;
  `}
  ${({ theme, padding }) => theme.media.mobile`
    padding: ${padding?.mobile ?? 96}px 0px;
  `}
`;
