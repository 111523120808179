import { BackQuoteArgs } from "@style/const";
import { css, CSSProp } from "styled-components";


interface FontTypeProps {
  name: string,
  size: number;
  weight: number,
  height: number,
  spacing?: string,
}

export class FontType implements FontTypeProps {
  /** 디바이스 사이즈 객체 
   * @param {string} name 이름
   * @param {number} size font-size
   * @param {number} weight font-weight
   * @param {number} height line-height
   * @param {number} spacing letter-spacing, string으로 넣어야함 ex) '16px'
  */
  constructor(public name: string, public size: number, public weight: number, public height: number, public spacing?: string) {
  }

  public get fontSize() {
    return `font-size: ${this.size}px;`;
  }

  public get fontWeight() {
    return `font-weight: ${this.weight};`;
  }
  public get lineHeight() {
    return `line-height: ${this.height}px;`;
  }
  public get letterSpacing() {
    if (this.spacing === undefined) {
      return 'letter-spacing: -0.015em;';
    } else {
      return `letter-spacing: ${this.spacing};`;
    }
  }

  public get fontCss() {
    return `
    font-family: Pretendard; 
    text-align: left; 
    ${this.letterSpacing} 
    ${this.fontSize} 
    ${this.fontWeight} 
    ${this.lineHeight}`;
  }
}


export const HeadlineType = {
  h1: new FontType("h1", 80, 700, 110),
  h2: new FontType("h2", 70, 700, 91),
  h3: new FontType("h3", 64, 700, 83),
  h4: new FontType("h4", 58, 700, 78),
  h5: new FontType("h5", 40, 700, 52)
} as const;

export const SubtitleType = {
  subtitle1: new FontType("subtitle1", 30, 700, 39),
  subtitle2: new FontType("subtitle2", 24, 700, 36),
  subtitle3: new FontType("subtitle3", 20, 700, 32),
  subtitle4: new FontType("subtitle4", 18, 700, 30),
  subtitle5: new FontType("subtitle5", 16, 700, 24),
} as const;

export const BodyType = {
  body1: new FontType("body1", 30, 200, 45),
  body2: new FontType("body2", 28, 300, 40),
  body3: new FontType("body3", 20, 400, 32),
  body4: new FontType("body4", 18, 400, 30),
  body5: new FontType("body5", 16, 400, 24),
  body6: new FontType("body6", 16, 400, 22),
} as const;

export const EtcType = {
  button: new FontType("button", 20, 700, 32, "0px"),
  caption: new FontType("caption", 14, 300, 18),
} as const;

export const FontTypes = {
  ...HeadlineType,
  ...SubtitleType,
  ...BodyType,
  ...EtcType
} as const;

type fontKeys = keyof typeof FontTypes;

export default Object.entries(FontTypes).reduce((acc, entry) => {
  acc[entry[0] as fontKeys] = (literals: TemplateStringsArray, ...args: BackQuoteArgs): CSSProp =>
    css`
    ${entry[1].fontCss}
  `;
  return acc;
}, {} as Record<
  keyof typeof FontTypes,
  (l: TemplateStringsArray, ...p: BackQuoteArgs) => CSSProp
>);