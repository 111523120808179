import styled from "styled-components";

const Subtitle1 = styled.p`
  ${({ theme }) => theme.fonts.subtitle1``}
`;

const Subtitle2 = styled.p`
  ${({ theme }) => theme.fonts.subtitle2``}
`;

const Subtitle3 = styled.p`
  ${({ theme }) => theme.fonts.subtitle3``}
`;

const Subtitle4 = styled.p`
  ${({ theme }) => theme.fonts.subtitle4``}
`;

const Subtitle5 = styled.p`
  ${({ theme }) => theme.fonts.subtitle5``}
`;


const Subtitle= {
  One: Subtitle1,
  Two: Subtitle2,
  Three: Subtitle3,
  Four: Subtitle4,
  Five: Subtitle5
};

export default Subtitle;
