import styled from "styled-components";

const Headline1 = styled.h1`
  ${({ theme }) => theme.fonts.h1``}
`;

const Headline2 = styled.h2`
  ${({ theme }) => theme.fonts.h2``}
`;

const Headline3 = styled.h3`
  ${({ theme }) => theme.fonts.h3``}
`;

const Headline4 = styled.h4`
  ${({ theme }) => theme.fonts.h4``}
`;

const Headline5 = styled.h5`
  ${({ theme }) => theme.fonts.h5``}
`;


const Headline= {
  One: Headline1,
  Two: Headline2,
  Three: Headline3,
  Four: Headline4,
  Five: Headline5
};

export default Headline;
