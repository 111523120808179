import {
  ImageContainer,
  LanguageSwitcher,
  MediaWrapper,
} from "@components/common/etc";
import { theme } from "@style/theme";
import styled from "styled-components";

import KoreanMainImage from "@assets/image/about/main/ko";

import BodyContent from "@components/about/main/body";
import HeadLineContent from "@components/about/main/headline";
import EnglishMainImage from "@assets/image/about/main/en";
import { FirstSection } from "@components/layout/banner";

const MainSectionWrapper = styled(FirstSection)`
  background-color: ${theme.color.deepBlue};
`;

const ContentContainer = styled(MediaWrapper)`
  ${({ theme }) => theme.media.desktop`
    padding: 225.5px 0px
  `}
  ${({ theme }) => theme.media.laptop`
    padding: 170px 0px
  `}
  ${({ theme }) => theme.media.tabletL`
    padding: 185px 0px
  `}
  ${({ theme }) => theme.media.tabletS`
    padding: 224px 0px
  `}
  ${({ theme }) => theme.media.mobile`
    padding: 129.5px 0px
  `}
`;

const MainSection = () => {
  return (
    <MainSectionWrapper>
      <ContentContainer>
        <LanguageSwitcher
          target="about"
          KoreanContainer={() => <ImageContainer image={KoreanMainImage} />}
          EnglishContainer={() => <ImageContainer image={EnglishMainImage} />}
        />
        <HeadLineContent />
        <BodyContent />
      </ContentContainer>
    </MainSectionWrapper>
  );
};

export default MainSection;
