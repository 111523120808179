import { DisclosureEntity } from "@fragment/Disclosure";
import type { DisclosureEntity$key as DisclosureEntityType } from "@fragment/__generated__/DisclosureEntity.graphql";
import { ReactComponent as folderSVG } from "@assets/icon/folder.svg";
import { useFragment } from "react-relay";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface DisclosureRowProps {
  item: DisclosureEntityType;
  index: number;
  page: number;
  total: number;
}

const DisclosureRowWrapper = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.color.gray500};

  &:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.color.gray500};
  }
  padding: 20px 24px;

  ${({ theme }) => theme.media.tabletL`
    padding: 22px 20px;
  `}

  ${({ theme }) => theme.media.tabletS`
    padding: 22px 20px;
  `}

  ${({ theme }) => theme.media.mobile`
    padding: 20px 0px;
    flex-direction: column;
  `}

  &:hover {
    background-color: ${({ theme }) => theme.color.gray100};
  }
  cursor: pointer;
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ theme }) => theme.fonts.body3}

  ${({ theme }) => theme.media.tabletL`
    ${theme.fonts.body4}
  `}

  ${({ theme }) => theme.media.tabletS`
    ${theme.fonts.body4}
  `}

  ${({ theme }) => theme.media.mobile`
    ${theme.fonts.body5}
  `}
`;

const Index = styled.span`
  font-weight: 700 !important;
  ${({ theme }) => theme.media.mobile`
    display: none;
  `}
`;

const Title = styled.span`
  font-weight: 600 !important;
  margin-left: 20px;
  ${({ theme }) => theme.media.mobile`
    margin-left: 0px;
  `}
`;

const DateText = styled.span`
  color: ${({ theme }) => theme.color.gray500};
  ${({ theme }) => theme.media.mobile`
    margin-top: 16px;
  `}
`;

const FolderIcon = styled(folderSVG)`
  margin-right: 40px;
  ${({ theme }) => theme.media.tabletL`
    margin-right: 30px;
  `}

  ${({ theme }) => theme.media.tabletS`
      margin-right: 20px;
  `}

  ${({ theme }) => theme.media.mobile`
    display: none;
  `}
`;

const DisclosureRow: React.FC<DisclosureRowProps> = (props) => {
  const { t } = useTranslation("disclosure");
  const { item, index, page, total } = props;
  const data = useFragment(DisclosureEntity, item);
  const idx = total - (page - 1) * 10 - index;

  return (
    data && (
      <DisclosureRowWrapper to={`/disclosure/${data.id}`}>
        <TextBox>
          <Index>{`${idx}`.padStart(2, "0")}</Index>
          <Title>{`[${t(data.attributes.type)}] ${
            data.attributes.title
          }`}</Title>
        </TextBox>
        <TextBox>
          {data.attributes.files.data.length > 0 && <FolderIcon />}
          <DateText>{data.attributes.published_date}</DateText>
        </TextBox>
      </DisclosureRowWrapper>
    )
  );
};
export default DisclosureRow;
