/**
 * @generated SignedSource<<06c806c7b01292e6069e8a29f9135473>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ENUM_DISCLOSURE_TYPE = "occasional" | "regularly" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Disclosure$data = {
  readonly __typename: "Disclosure";
  readonly content: string;
  readonly files: {
    readonly __typename: "UploadFileRelationResponseCollection";
    readonly data: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"UploadFileEntity">;
    }>;
  };
  readonly published_date: any;
  readonly title: string;
  readonly type: ENUM_DISCLOSURE_TYPE;
  readonly " $fragmentType": "Disclosure";
} | null;
export type Disclosure$key = {
  readonly " $data"?: Disclosure$data;
  readonly " $fragmentSpreads": FragmentRefs<"Disclosure">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Disclosure",
  "selections": [
    {
      "kind": "RequiredField",
      "field": (v0/*: any*/),
      "action": "LOG",
      "path": "__typename"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      "action": "LOG",
      "path": "title"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      "action": "LOG",
      "path": "type"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "content",
        "storageKey": null
      },
      "action": "LOG",
      "path": "content"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "published_date",
        "storageKey": null
      },
      "action": "LOG",
      "path": "published_date"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "UploadFileRelationResponseCollection",
        "kind": "LinkedField",
        "name": "files",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": (v0/*: any*/),
            "action": "LOG",
            "path": "files.__typename"
          },
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": null,
              "concreteType": "UploadFileEntity",
              "kind": "LinkedField",
              "name": "data",
              "plural": true,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "UploadFileEntity"
                }
              ],
              "storageKey": null
            },
            "action": "LOG",
            "path": "files.data"
          }
        ],
        "storageKey": null
      },
      "action": "LOG",
      "path": "files"
    }
  ],
  "type": "Disclosure",
  "abstractKey": null
};
})();

(node as any).hash = "94ab6db6415e68ebbd95425e5a6e3304";

export default node;
