import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ReactComponent as findSVG } from "@assets/icon/find.svg";

interface SearchContainerProps {
  search: string;
  setSearch: (value: string) => void;
}

const SearchWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  margin-bottom: 40px;

  ${({ theme }) => theme.media.tabletL`
    margin-bottom: 30px;
  `}

  ${({ theme }) => theme.media.tabletS`
    margin-bottom: 30px;
  `}

  ${({ theme }) => theme.media.mobile`
    margin-bottom: 20px;
  `}
`;

const SearchBox = styled.div`
  position: relative;
  width: 80%;

  ${({ theme }) => theme.media.tabletS`
    width: 100%;
  `}

  ${({ theme }) => theme.media.mobile`
    width: 100%;
  `}
`;

const Search = styled.input`
  box-sizing: border-box;
  width: 100%;
  padding: 20px 30px;
  border: 1px solid ${({ theme }) => theme.color.gray500};
  ${({ theme }) => theme.media.mobile`
    padding: 15px 20px;
    width: 100%;
  `}

  &:focus {
    outline: none;
    border: 2px solid ${({ theme }) => theme.color.deepBlue};
  }

  &::placeholder {
    color: ${({ theme }) => theme.color.gray500};
  }
`;

const FindIcon = styled(findSVG)`
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto 0;

  ${({ theme }) => theme.media.mobile`
    right: 15px;
  `}
`;

const SearchContainer: React.FC<SearchContainerProps> = (props) => {
  const { search, setSearch } = props;
  const { t } = useTranslation("disclosure");
  const placeholder = t("input.placeholder");

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value);

  return (
    <SearchWrapper>
      <SearchBox>
        <Search placeholder={placeholder} onChange={onChange} value={search} />
        <FindIcon />
      </SearchBox>
    </SearchWrapper>
  );
};
export default SearchContainer;
