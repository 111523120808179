import BodyText from "@components/common/bodyText";
import {
  ButtonWrapper,
  FlexContainer,
  MediaWrapper,
} from "@components/common/etc";
import GoToTopIcon from "@assets/image/footer/gototop.png";
import BlogIcon from "@assets/image/footer/blog.svg";
import YoutubeIcon from "@assets/image/footer/youtube.svg";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const FooterWrapper = styled.footer`
  box-sizing: border-box;
  color: white;
  width: 100%;
  background-color: ${({ theme }) => theme.color.deepBlue};

  padding: 80px 0px;
  border-top: 1px solid #8d93a6;
`;

const ContentContainer = styled(MediaWrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 12px 0px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 24px;
`;

const InfoText = styled(BodyText.Five)`
  color: ${({ theme }) => theme.color.sub2};
  margin-bottom: 24px;
`;

const Copyright = styled(BodyText.Five)`
  color: ${({ theme }) => theme.color.sub2};
  margin-bottom: 24px;
`;

const GoToTop = styled(ButtonWrapper)`
  height: 48px;
  width: 48px;

  img {
    height: 48px;
    width: 48px;
  }

  &:hover,
  &:focus ${ButtonWrapper} {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const SocialWrapper = styled(FlexContainer)``;
const SocialLink = styled(ButtonWrapper)`
  height: 40px;
  width: 40px;
  margin-right: 8px;
  border-radius: 20px;

  &:last-child {
    margin-right: 0px;
  }
  &:hover,
  &:focus ${ButtonWrapper} {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const Footer: React.FC = () => {
  const { t } = useTranslation("common");

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <FooterWrapper>
      <ContentContainer>
        <InfoWrapper>
          <InfoText>
            {t("footer.info1")}
            <br />
            {t("footer.info2")}
            <br />
            {t("footer.info3")}
          </InfoText>
          <Copyright>© QUANTIT</Copyright>
          <SocialWrapper>
            <SocialLink onClick={()=>{window.open("https://www.youtube.com/@quantit_io")}}>
              <img src={YoutubeIcon} alt="quantit-youtube"></img>
            </SocialLink>
            <SocialLink onClick={()=>{window.open("https://blog.naver.com/quantit_io")}}>
              <img src={BlogIcon} alt="quantit-blog"></img>
            </SocialLink>
          </SocialWrapper>
        </InfoWrapper>
        <GoToTop onClick={scrollToTop}>
          <img src={GoToTopIcon} alt="go-to-top"></img>
        </GoToTop>
      </ContentContainer>
    </FooterWrapper>
  );
};

export default Footer;
