import { ImageSetProps } from "@components/common/etc";
import Desktop from "./desktop.png";
import Laptop from "./laptop.png";
import TabletLarge from "./tabletLarge.png";
import TabletSmall from "./tabletSmall.png";
import Mobile from "./mobile.png";

const CultureBannerImage: ImageSetProps = {
    desktop: { src: Desktop, height: 184, width: 1440, },
    laptop: { src: Laptop, height: 160, width: 1280, },
    tabletL: { src: TabletLarge, height: 160, width: 905, },
    tabletS: { src: TabletSmall, height: 96, width: 600, },
    mobile: { src: Mobile, height: 80, width: 360, },
    alt: "culture-banner-image"
}

export default CultureBannerImage
