import DirectionalLogo from "@assets/image/about/relative/directional";
import QtadvisorLogo from "@assets/image/about/relative/qtadvisor";
import QuantfolioLogo from "@assets/image/about/relative/quantfolio";
import VaivLogo from "@assets/image/about/relative/vaiv";
import { ImageSetProps } from "@components/common/etc";

export interface RelativeComProps{
    image: ImageSetProps
    descriptionKey: string;
    url?: string;
}

const RelativeComData: Array<RelativeComProps> = [
    {
        image: VaivLogo,
        descriptionKey: "relative.vaiv",
        url: "http://www.vaiv.kr/"
    },
    {
        image: QtadvisorLogo,
        descriptionKey: "relative.qtadvisor",
        url: "https://www.qt-advisor.com/"
    },
    {
        image: QuantfolioLogo,
        descriptionKey: "relative.quantfolio",
        url: "https://quantfolio.io/"
    },
    {
        image: DirectionalLogo,
        descriptionKey: "relative.directional"
    },
]

export default RelativeComData;