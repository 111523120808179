import React from "react";
import { Helmet } from "react-helmet-async";

interface HelmetContainerProps {
  title?: string;
  description?: string;
}

const HelmetContainer: React.FC<HelmetContainerProps> = (props) => {
  const { title, description } = props;

  const _title = () => title ?? "Quantit | 퀀팃 | AI Fintech Company";
  const _description = () =>
    description ??
    "AI Fintech Company, 빅데이터 기반 금융투자에 특화된 AI 자산관리 솔루션을 제공합니다.";
  return (
    <Helmet>
      <title>{_title()}</title>
      <meta name="description" content={_description()} />
      <link rel="canonical" href="https://www.quantit.io" />
      <meta property="og:title" content={_title()} />
      <meta property="og:description" content={_description()} />
      <meta property="og:image" content="/og/card.png" />
      <meta property="og:image:width" content="500" />
      <meta property="og:image:height" content="500" />
      <meta property="og:url" content="https://www.quantit.io" />
      <meta property="og:site_name" content={_title()} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={_title()} />
      <meta name="twitter:description" content={_description()} />
      <meta name="twitter:image" content="/og/card.png" />
    </Helmet>
  );
};

export default HelmetContainer;
