import { ImageContainerProps } from "@components/common/etc";
import styled from "styled-components";

interface PageBannerProps extends ImageContainerProps {
  size?: string;
  position?: string;
  bgcolor?: string;
}

const PageBanner = styled.div<PageBannerProps>`
  width: calc(100% + 2px);
  height: ${({ image }) => image.desktop.height + 2}px;
  background-color: ${({ bgcolor }) => bgcolor ?? "transparent"};
  background-image: url(${({ image }) => image.desktop.src});
  background-repeat: no-repeat;
  background-size: ${({ size }) => size ?? "cover"};
  background-position: ${({ position }) => position ?? "center"};
  border: none;
  position: absolute;
  left: -2px;
  top: 70px;

  ${({ image, theme }) => theme.media.laptop`
    height: ${image.laptop.height + 2}px;
    background-image: url(${image.laptop.src});
  `}
  ${({ image, theme, position }) => theme.media.tabletL`
    height: ${image.laptop.height + 2}px;
    background-image: url(${image.tabletL.src});
    background-size: cover;
    background-position: ${position ?? "center"};
  `}
  ${({ image, theme, position }) => theme.media.tabletS`
    height: ${image.tabletS.height + 2}px;
    background-image: url(${image.tabletS.src});
    background-size: cover;
    background-position: ${position ?? "center"};
  `}
  ${({ image, theme, position }) => theme.media.mobile`
    height: ${image.mobile.height + 2}px;
    background-image: url(${image.mobile.src});
    background-size: cover;
    background-position: ${position ?? "center"};
  `}
`;

export const FirstSection = styled.section`
  padding-top: 72px;
`;

export const FirstSectionWithBanner = styled(FirstSection)`
  padding-top: calc(184px + 72px);

  ${({ theme }) => theme.media.laptop`
    padding-top: calc(160px + 72px);
  `}
  ${({ theme }) => theme.media.tabletL`
    padding-top: calc(160px + 72px);
  `}
  ${({ theme }) => theme.media.tabletS`
    padding-top: calc(96px + 72px);
  `}
  ${({ theme }) => theme.media.mobile`
    padding-top: calc(80px + 72px);
  `}
`;

export default PageBanner;
