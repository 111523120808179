import { createGlobalStyle } from "styled-components";
import PretendardBlack from '@fonts/Pretendard-Black.woff';
import PretendardExtraBold from '@fonts/Pretendard-ExtraBold.woff';
import PretendardBold from '@fonts/Pretendard-Bold.woff';
import PretendardSemiBold from '@fonts/Pretendard-SemiBold.woff';
import PretendardMedium from '@fonts/Pretendard-Medium.woff';
import PretendardRegular from '@fonts/Pretendard-Regular.woff';
import PretendardLight from '@fonts/Pretendard-Light.woff';
import PretendardExtraLight from '@fonts/Pretendard-ExtraLight.woff';
import PretendardThin from '@fonts/Pretendard-Thin.woff';
import { theme } from "@style/theme";

const GlobalStyles = createGlobalStyle`
    // css 초기값 정의
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed, 
    figure, figcaption, footer, header, hgroup, 
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    article, aside, details, figcaption, figure, 
    footer, header, hgroup, menu, nav, section {
        display: block;
    }
    body {
        line-height: 1;
        overflow-x: hidden;
        width: 100%;
        color: ${theme.color.deepBlue};
        min-height: 100vh;
    }
    ol, ul {
        list-style: none;
    }
    blockquote, q {
        quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }
    button {
        all: unset;
        cursor: pointer;
    }
    a{
        color: inherit;
        text-decoration: none;
    }
    
    @font-face {
        font-family: 'Pretendard';
        font-weight: 900;
        font-display: swap;
        src: url(${PretendardBlack}) format('woff');
    }
    
    @font-face {
        font-family: 'Pretendard';
        font-weight: 800;
        font-display: swap;
        src: url(${PretendardExtraBold}) format('woff');
    }
    
    @font-face {
        font-family: 'Pretendard';
        font-weight: 700;
        font-display: swap;
        src: url(${PretendardBold}) format('woff');
    }
    
    @font-face {
        font-family: 'Pretendard';
        font-weight: 600;
        font-display: swap;
        src: url(${PretendardSemiBold}) format('woff');
    }
    
    @font-face {
        font-family: 'Pretendard';
        font-weight: 500;
        font-display: swap;
        src: url(${PretendardMedium}) format('woff');
    }
    
    @font-face {
        font-family: 'Pretendard';
        font-weight: 400;
        font-display: swap;
        src: url(${PretendardRegular}) format('woff');
    }
    
    @font-face {
        font-family: 'Pretendard';
        font-weight: 300;
        font-display: swap;
        src: url(${PretendardLight}) format('woff');
    }
    
    @font-face {
        font-family: 'Pretendard';
        font-weight: 200;
        font-display: swap;
        src: url(${PretendardExtraLight}) format('woff');
    }
    
    @font-face {
        font-family: 'Pretendard';
        font-weight: 100;
        font-display: swap;
        src: url(${PretendardThin}) format('woff');
    }
    #root{
        position: relative;
    }
`;

export default GlobalStyles;