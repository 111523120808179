import { RelativeComProps } from "@components/about/relative/const ";
import {
  ButtonWrapper,
  FlexContainer,
  ImageContainer,
} from "@components/common/etc";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface CompanyCardProps {
  data: RelativeComProps;
}

const CompanyCardWrapper = styled(FlexContainer)`
  border: 1px solid ${({ theme }) => theme.color.gray200};
  margin-bottom: 80px;
  padding: 32px 24px;

  &:last-child {
    margin-bottom: 0px;
  }

  ${({ theme }) => theme.media.tabletS`
    flex-direction: column;
    align-items: center;
  `}
  ${({ theme }) => theme.media.mobile`
    flex-direction: column;
    align-items: center;
  `}
`;

const LogoWrapper = styled.div`
  margin-right: 24px;
  width: 264px;

  ${({ theme }) => theme.media.laptop`
    width: 202px;
  `}
  ${({ theme }) => theme.media.tabletL`
    width: 196px;
  `}
  ${({ theme }) => theme.media.tabletS`
    width: 196px;
    margin-right: 0px;
    margin-bottom: 24px;
  `}
  ${({ theme }) => theme.media.mobile`
    width: 196px;
    margin-right: 0px;
    margin-bottom: 24px;
  `}
`;
const InfoWrapper = styled(FlexContainer)`
  flex-direction: column;

  ${({ theme }) => theme.media.tabletS`
    align-items: center;
  `}
  ${({ theme }) => theme.media.mobile`
    align-items: center;
  `}
`;
const DescriptionText = styled.p`
  margin-bottom: 24px;
  ${({ theme }) => theme.fonts.subtitle2}
  ${({ theme }) => theme.media.tabletL`
    ${theme.fonts.subtitle3}
  `}
  ${({ theme }) => theme.media.tabletS`
    ${theme.fonts.subtitle3}
  `}
  ${({ theme }) => theme.media.mobile`
    ${theme.fonts.subtitle3}
  `}
`;

const WebSiteButtonWrapper = styled(ButtonWrapper)`
  box-sizing: border-box;
  width: fit-content;
  padding: 16px 56px;
  border: 2px solid ${({ theme }) => theme.color.deepBlue};

  &:hover,
  &:focus ${ButtonWrapper} {
    background-color: ${({ theme }) => theme.color.deepBlue}33;
  }
`;

const CompanyCard: React.FC<CompanyCardProps> = (props) => {
  const { image, descriptionKey, url } = props.data;
  const { t } = useTranslation("about");

  const openWebsite = () => {
    window.open(url ?? "#", "_blank");
  };
  return (
    <CompanyCardWrapper>
      <LogoWrapper>
        <ImageContainer image={image} />
      </LogoWrapper>
      <InfoWrapper>
        <DescriptionText>{t(descriptionKey)}</DescriptionText>
        {url !== undefined ? (
          <WebSiteButtonWrapper onClick={openWebsite}>
            {t("relative.button")}
          </WebSiteButtonWrapper>
        ) : (
          <></>
        )}
      </InfoWrapper>
    </CompanyCardWrapper>
  );
};

export default CompanyCard;
