import {
  AddressPanel,
  InquiriesButtonProps,
  MailButton,
} from "@components/about/Inquiries/button";
import SectionLabel from "@components/common/label";
import { ContentContainer } from "@components/common/etc";
import Subtitle from "@components/common/subtitle";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

export interface InquiriesSectionProps extends InquiriesButtonProps {
  backgroundColor?: string;
}

const InquiriesSectionWrapper = styled.section<InquiriesSectionProps>`
  color: ${({ fontcolor }) => fontcolor ?? "inherit"};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ?? "transparent"};
`;

const BodyText = styled(Subtitle.One)`
  margin-bottom: 80px;
  ${({ theme }) => theme.media.tabletL`
    ${theme.fonts.subtitle2}
  `};
  ${({ theme }) => theme.media.tabletS`
    ${theme.fonts.subtitle2}
  `};
  ${({ theme }) => theme.media.mobile`
    ${theme.fonts.subtitle3}
  `};
`;

export const InquiriesSectionContent: React.FC<InquiriesSectionProps> = (
  props
) => {
  const { fontcolor, hovercolor } = props;
  const { t } = useTranslation("about");
  return (
    <>
      <ContentContainer>
        <SectionLabel languageFileName="about" labelKey="inquiries.label" />
        <BodyText>{t("inquiries.body")}</BodyText>
        <MailButton fontcolor={fontcolor} hovercolor={hovercolor} />
        <AddressPanel fontcolor={fontcolor} hovercolor={hovercolor} />
      </ContentContainer>
    </>
  );
};

const InquiriesSection: React.FC<InquiriesSectionProps> = (props) => {
  const { fontcolor, backgroundColor, hovercolor } = props;

  return (
    <InquiriesSectionWrapper
      fontcolor={fontcolor}
      backgroundColor={backgroundColor}
    >
      <InquiriesSectionContent fontcolor={fontcolor} hovercolor={hovercolor} />
    </InquiriesSectionWrapper>
  );
};

export default InquiriesSection;
