import Layout from "@components/layout";
import { PageProps } from "@pages/const";
import { Outlet, useParams } from "react-router-dom";

const DisclosurePage: React.FC<PageProps> = (props) => {
  const { title } = props;
  const params = useParams();

  return (
    <Layout title={title}>
      <Outlet context={{ disclosureId: params.disclosureId }} />
    </Layout>
  );
};

export default DisclosurePage;
