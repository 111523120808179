import styled from "styled-components";

export const TitleText = styled.p`
  margin-bottom 80px;
  ${({ theme }) => theme.fonts.h4}

  ${({ theme }) => theme.media.tabletL`
    ${theme.fonts.h5}
  `}
  ${({ theme }) => theme.media.tabletS`
    ${theme.fonts.h5}
  `}
  ${({ theme }) => theme.media.mobile`
    ${theme.fonts.subtitle2}
  `}
`;
