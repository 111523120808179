import {
  HistoryItemProps,
  HistoryYearProps,
} from "@components/about/history/const";
import { FlexContainer } from "@components/common/etc";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface HistoryRowProps {
  data: HistoryItemProps;
}

const HistoryRowWrapper = styled(FlexContainer)`
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 80px;
  }

  ${({ theme }) => theme.fonts.subtitle1}
  ${({ theme }) => theme.media.tabletL`
    ${theme.fonts.subtitle2}
    &:last-child {
        margin-bottom: 56px;
    }
  `}
  ${({ theme }) => theme.media.tabletS`
    ${theme.fonts.subtitle2}
    &:last-child {
        margin-bottom: 56px;
    }
  `}
  ${({ theme }) => theme.media.mobile`
    ${theme.fonts.subtitle3}
    &:last-child {
        margin-bottom: 56px;
    }
  `}
`;

const MonthText = styled.p`
  margin-right: 24px;
  width: 40px;
  ${({ theme }) => theme.media.tabletL`
    width: 49px;
  `}
  ${({ theme }) => theme.media.tabletS`
    width: 49px;
  `}
  ${({ theme }) => theme.media.mobile`
    width: 49px;
  `}
`;

const YearRowText = styled.p`
  flex: 1;
`;

const HistoryRow: React.FC<HistoryRowProps> = (props) => {
  const { month, descriptionKey } = props.data;
  const { t } = useTranslation("about");
  return (
    <HistoryRowWrapper>
      <MonthText>{month}</MonthText>
      <YearRowText>{t(descriptionKey)}</YearRowText>
    </HistoryRowWrapper>
  );
};

interface YearPanelProps {
  data: HistoryYearProps;
}

const YearPanelWrapper = styled(FlexContainer)`
  justify-content: space-between;
  margin-bottom: 80px;
  &:last-child {
    margin-bottom: 0px;
  }

  ${({ theme }) => theme.media.mobile`
    flex-direction: column;
  `}
`;

const YearText = styled.p`
  margin-right: 24px;
  width: 168px;
  ${({ theme }) => theme.fonts.h5}

  ${({ theme }) => theme.media.laptop`
    width: 127px;
  `}

  ${({ theme }) => theme.media.tabletL`
    width: 123px;
    ${theme.fonts.subtitle1}
  `}

  ${({ theme }) => theme.media.tabletS`
    width: 123px;
    ${theme.fonts.subtitle1}
  `}

  ${({ theme }) => theme.media.mobile`
    width: 123px;
    ${theme.fonts.subtitle1}
    margin-right: 0px;
    margin-bottom: 24px;
  `}
`;

const RowWrapper = styled.div`
  flex: 1;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray300};
`;

const YearPanel: React.FC<YearPanelProps> = (props) => {
  const { year, items } = props.data;
  return (
    <YearPanelWrapper>
      <YearText>{year}</YearText>
      <RowWrapper>
        {items.map((data) => (
          <HistoryRow key={data.descriptionKey} data={data} />
        ))}
      </RowWrapper>
    </YearPanelWrapper>
  );
};

export default YearPanel;
