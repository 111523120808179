import React from "react";

export interface PageProps {
    title: string;
}

export interface BasePageRouteProps {
    name: string;
    href: string;
    target?: string;
}

export interface PageMenuProps extends BasePageRouteProps {
    menu: boolean;
}

export interface PageRouteProps extends PageMenuProps {
    element: React.FC<PageProps>;
}

export const MenuList: Array<BasePageRouteProps> = [
    {
        name: "home",
        href: "/",
    },
    {
        name: "product",
        href: "/product",
    },
    {
        name: "recruit",
        href: "https://quantit.career.greetinghr.com",
        target: "_blank",
    },
    {
        name: "inquiries",
        href: "/inquiries",
    },
    {
        name: "disclosure",
        href: "/disclosure",
    },
];