import { CheckMediaQuery } from "@/utils";
import OllyFullImage from "@assets/image/product/olly";
import RabitFullImage from "@assets/image/product/rabit";
import {
  ButtonWrapper,
  ContentContainer,
  FlexContainer,
  ImageSetProps,
  ImageContainer,
} from "@components/common/etc";
import SectionLabel from "@components/common/label";
import { FontTypes } from "@style/fonts";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface WebsiteButtonProps {
  url: string;
}

const WebsiteButtonWrapper = styled(ButtonWrapper)`
  ${({ theme }) => theme.fonts.subtitle5}
  padding: 16px 56px;
  border: 2px solid ${({ theme }) => theme.color.deepBlue};

  &:hover,
  &focus {
    background-color: ${({ theme }) => theme.color.deepBlue}33;
  }
`;

const WebsiteButton: React.FC<WebsiteButtonProps> = (props) => {
  const { url } = props;
  const { t } = useTranslation("product");

  const openWebsite = () => {
    window.open(url, "_blank");
  };

  return (
    <WebsiteButtonWrapper onClick={openWebsite}>
      {t("product.button")}
    </WebsiteButtonWrapper>
  );
};

interface ServiceContainerProps extends WebsiteButtonProps {
  name: string;
  image: ImageSetProps;
}

const ServiceWrapper = styled(FlexContainer)`
  flex-direction: row-reverse;

  ${({ theme }) => theme.media.tabletS`
    flex-direction: column;
  `}
  ${({ theme }) => theme.media.mobile`
    flex-direction: column;
  `}
`;

const ServiceInfoWrapper = styled.div`
  margin-left: 24px;

  ${({ theme }) => theme.media.tabletL`
    margin-left: 0px;
  `}
  ${({ theme }) => theme.media.tabletS`
    margin-left: 0px;
  `}
  ${({ theme }) => theme.media.mobile`
    margin-left: 0px;
  `}
`;

const ServiceTitle = styled.p`
  ${({ theme }) => theme.fonts.h4}
  margin-bottom: 24px;
  ${({ theme }) => theme.media.tabletS`
    ${theme.fonts.h5}
    margin-bottom: 16px;
  `}
  ${({ theme }) => theme.media.mobile`
    ${theme.fonts.subtitle1}
    margin-bottom: 8px;
  `}
`;
const ServiceSubtitle = styled.p`
  ${({ theme }) => theme.fonts.subtitle1}
  margin-bottom: 80px;

  ${({ theme }) => theme.media.laptop`
    margin-bottom: 48px;
  `}
  ${({ theme }) => theme.media.tabletL`
    margin-bottom: 48px;
  `}
  ${({ theme }) => theme.media.tabletS`
    ${theme.fonts.subtitle3}
    margin-bottom: 40px;
  `}
  ${({ theme }) => theme.media.mobile`
    ${theme.fonts.subtitle3}
    margin-bottom: 40px;
  `}
`;

const ServiceDescription = styled.p`
  ${({ theme }) => theme.fonts.body3}
  margin-bottom: 80px;

  ${({ theme }) => theme.media.mobile`
    ${theme.fonts.body5}
    margin-bottom: 24px;
  `}
`;

const ImageBox = styled.div`
  img {
    object-fit: contain;
  }
`;

const WebsiteButtonBox = styled(FlexContainer)`
  margin-top: 80px;
  ${({ theme }) => theme.media.laptop`
    margin-top: 48px;
  `}
  ${({ theme }) => theme.media.tabletL`
    margin-top: 48px;
  `}
  ${({ theme }) => theme.media.tabletS`
    justify-content: center;
    margin-top: 24px;
  `}
  ${({ theme }) => theme.media.mobile`
    justify-content: center;
    margin-top: 24px;
  `}
`;

const ServiceContainer: React.FC<ServiceContainerProps> = (props) => {
  const { name, image, url } = props;
  const mq = CheckMediaQuery();
  const { t } = useTranslation("product");

  return (
    <ServiceWrapper>
      <ServiceInfoWrapper>
        <ServiceTitle>{t(`${name}.title`)}</ServiceTitle>
        <ServiceSubtitle>{t(`${name}.subtitle`)}</ServiceSubtitle>
        <ServiceDescription>{t(`${name}.description`)}</ServiceDescription>
        {(mq.isDesktop || mq.isLaptop || mq.isTabletLarge) && (
          <WebsiteButton url={url} />
        )}
      </ServiceInfoWrapper>
      <ImageBox>
          <ImageContainer image={image} />
        </ImageBox>
      {(mq.isTabletSmall || mq.isMobile) && (
        <WebsiteButtonBox>
          <WebsiteButton url={url} />
        </WebsiteButtonBox>
      )}
    </ServiceWrapper>
  );
};

const RabitSection = () => {
  const url = "https://rabit.bot/";
  return (
    <section id="rabit-product-section">
      <ContentContainer>
        <ServiceContainer name="rabit" image={RabitFullImage} url={url} />
      </ContentContainer>
    </section>
  );
};

const OllySection = () => {
  const url = "https://olly-ai.com/";
  return (
    <section id="olly-product-section">
      <ContentContainer>
        <SectionLabel
          languageFileName="product"
          labelKey="product.label"
          fontType={{ tabletLarge: FontTypes.subtitle1 }}
          marginBottom={{
            tabletSmall: 40,
            mobile: 40,
          }}
        />
        <ServiceContainer name="olly" image={OllyFullImage} url={url} />
      </ContentContainer>
    </section>
  );
};
const Services = {
  Olly: OllySection,
  Rabit: RabitSection,
};
export default Services;
